import React, { FC, useMemo } from 'react';
import { ID, iOption } from '@models';
import CheckboxInput from '@components/CheckboxInput/CheckboxInput';
import { toggleInArray } from '@core/old_helpers';

const FilterCheckboxGroup: FC<{
    title: string;
    options: iOption[];
    value: ID[];
    onChange: (value: ID[]) => void;
    isDisabled?: boolean;
}> = ({
    title,
    options,
    value,
    onChange,
    isDisabled = false,
}) => {

    const isAllSelected = useMemo(() => options.length === value.length, [options, value]);
    const isSelected = useMemo(() => value.length > 0, [value]);

    const handleToggle = (id: ID) => {
        onChange(toggleInArray(value, id));
    }

    const handleToggleAll = () => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(options.map(o => o.id));
        }
    }

    return (
        <div className="AdminFilters__column">
            <CheckboxInput
                isBlueCheck
                onChange={handleToggleAll}
                labelText={title}
                isChecked={isAllSelected}
                isPartialChecked={isSelected}
                disabled={isDisabled}
            />
            <br/>
            {options.map((option, index) => (
                <div key={index} className="venues-form__item">
                    <CheckboxInput
                        isSmall
                        onChange={() => handleToggle(option.id)}
                        labelText={option.title}
                        isChecked={value.includes(option.id)}
                        disabled={isDisabled}
                    />
                </div>
            ))}
        </div>
    )
}

export default FilterCheckboxGroup;