import React, { useReducer, useLayoutEffect, useRef, useState, useEffect } from "react";
import "./SearchPageInput.scss";
import { useLocation } from "react-router-dom";

const INPUT_SHIFT_LIMIT = 10;

const SearchPageInput = (
    {
        onChange,
        onEnter,
    }
) => {
    const location = useLocation();
    const span = useRef(null);
    const input = useRef(null);
    const [isCaretShown, setIsCaretShown] = useState(true);
    const [value, setValue] = useState('');
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!location.search) {
            setValue('');
            onChange('');
        }
    }, [location]);

    const handleOnChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value, 500);
    };

    const handleOnKeyDown = (e) => {
        if (!isCaretShown) setIsCaretShown(true);
        if (e.keyCode === 27) onChange('');
        dispatch({
            tick: state.tick + 1,
        });
        if (value.length > 0 && e.keyCode === 13) {
            onEnter();
        }
    };

    useLayoutEffect(() => {
        setTimeout(() => {
            dispatch({
                tack: state.tack + 1,
            })
        }, 10)
    }, [state.tick]);

    return (
        <div className="SearchPageInput">
            <div className="SearchPageInput__back-text">
                {value}
            </div>
            <div className="SearchPageInput__input-container">
                <input
                    onChange={handleOnChange}
                    onKeyUp={handleOnKeyDown}
                    onClick={handleOnKeyDown}
                    onFocus={handleOnKeyDown}
                    spellCheck={false}
                    type="text"
                    className={`SearchPageInput__input ${value.length > INPUT_SHIFT_LIMIT ? 'SearchPageInput__input--small' : ''}`}
                    value={value}
                    placeholder="SEARCH"
                    autoFocus
                    onBlur={() => setIsCaretShown(false)}
                    ref={input}
                />
                {!!input.current && (
                    <span
                        className={`SearchPageInput__text ${value.length > INPUT_SHIFT_LIMIT ? 'SearchPageInput__text--small' : ''}`}
                        style={{
                            transform: `translateX(-${input.current.scrollLeft}px)`,
                            backgroundSize: `${input.current.scrollLeft}px 100px`
                        }}
                    >
                    <span
                        className="SearchPageInput__text-inner"
                        dangerouslySetInnerHTML={{ __html: value.substr(0, input.current.selectionEnd).replace(/\s/g, '&nbsp;') }}
                    />
                    <span
                        ref={span}
                        className="SearchPageInput__cursor-sizer"
                        dangerouslySetInnerHTML={{ __html: value.substr(input.current.selectionEnd, 1).replace(/\s/g, '&nbsp;') }}
                    />
                        {isCaretShown && (
                            <span
                                className={`SearchPageInput__caret ${value.length > INPUT_SHIFT_LIMIT ? 'SearchPageInput__caret--small' : ''}`}
                                style={{
                                    width: span.current && span.current.offsetWidth > 0 ? `${span.current.offsetWidth}px` : '',
                                }}
                            />
                        )}
                </span>
                )}
            </div>
        </div>
    )
};

const reducer = (state, action) => ({
    ...state,
    ...action,
});

const initialState = {
    tick: 0,
    tack: 0,
};
export default SearchPageInput;