import { CSSProperties, FC } from "react";
import cn from "classnames";
import "./NewTooltip.styles.scss";

export type positionType = Array<"left" | "right" | "center" | "above" | "below">;

interface iProps {
    position?: positionType;
    className?: string;
    style?: CSSProperties;
}
const NewTooltip: FC<iProps> = (
    {
        position = ["center", "above"],
        className,
        children,
        style,
    }
) => {
    const classNames = cn(
        "NewTooltip",
        className,
        ...position.map(p => `NewTooltip--${p}`),
    );

    return (
        <div
            className={classNames}
            style={style}
        >
            {children}
        </div>
    );
};

export default NewTooltip;
