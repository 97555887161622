import React, { Component, createRef } from "react";
import PT from "prop-types";
import './Bubble.scss';

export default class Bubble extends Component {
    constructor(props) {
        super(props);
        const windowWidth = window.innerWidth;

        // this.state = {
        //     context: this.canvasRef.current.context,
        // }

        this.canvasRef = createRef();
        this.FONT_SIZE = this.props.getRem(36);
        this.RADIUS_RANGE = this.props.getRem(7); // default: 7
        this.RADIUS_STEP = this.props.getRem(0.12); // default 0.08
        this.MOVE_STEP = this.props.getRem(props.isAnimated ? 0.5 : 0);
        this.BUBBLES_POPULATION_STEP_COUNT = 15; // default: 30
        this.state = {
            CANVAS_WIDTH: ((props.width || 300) / 1920) * windowWidth,
            CANVAS_HEIGHT: ((props.height || 300) / 1920) * windowWidth,
        };

        this.draw = this.draw.bind(this);
        window.addEventListener('resize', this.calculateDimensions, true);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.animationId);
        window.removeEventListener('resize', this.calculateDimensions, true);
    }

    componentDidMount() {
        const canvas = this.canvasRef.current;
        canvas.width = this.state.CANVAS_WIDTH;
        canvas.height = this.state.CANVAS_HEIGHT;

        this.ctx = canvas.getContext("2d");
        this.bubbles = new Map();

        this.draw();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isAnimated && this.props.isAnimated) {
            this.draw();
        }
        if (prevProps.isAnimated && !this.props.isAnimated) {
            cancelAnimationFrame(this.animationId);
        }
    }

    calculateDimensions = () => {
        if ( !this.ctx || !this.canvasRef.current ) return;
        this.ctx.clearRect(0, 0, this.state.CANVAS_WIDTH, this.state.CANVAS_HEIGHT);
        this.bubbles = new Map();
        this.setState({
            CANVAS_WIDTH: ((this.props.width || 300) / 1920) * window.innerWidth,
            CANVAS_HEIGHT: ((this.props.height || 300) / 1920) * window.innerWidth,
        });
        this.FONT_SIZE = this.props.getRem(36);
        cancelAnimationFrame(this.animationId);
        this.draw();
        const canvas = this.canvasRef.current;
        canvas.width = this.state.CANVAS_WIDTH;
        canvas.height = this.state.CANVAS_HEIGHT;
    };

    createBubble = () => {
        const r = Math.random() * this.RADIUS_RANGE;
        const phi = Math.random() * 2 * Math.PI;
        const move = this.state.CANVAS_WIDTH / 2 - 30 - r;

        return {
            move,
            r,
            phi
        };
    };

    drawMainCircle = () => {
        const grad = this.ctx.createLinearGradient(0, 0, 0, this.state.CANVAS_HEIGHT);
        this.props.colors.forEach((color, idx) =>
            grad.addColorStop(idx, color)
        );
        this.ctx.fillStyle = grad;

        this.ctx.beginPath();
        const x = this.state.CANVAS_WIDTH / 2;
        const y = this.state.CANVAS_HEIGHT / 2;
        const r = this.state.CANVAS_WIDTH / 2 - 30;
        this.ctx.arc(x, y, r, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.closePath();
    };

    drawBubble = ({ move, phi, r }, key) => {
        this.ctx.beginPath();

        const x = this.state.CANVAS_WIDTH / 2 + move * Math.cos(phi);
        const y = this.state.CANVAS_HEIGHT / 2 + move * Math.sin(phi);
        this.ctx.arc(x, y, r, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.closePath();
        this.bubbles.set(key, {
            phi,
            move: move + this.MOVE_STEP,
            r: r - this.RADIUS_STEP
        });
    };

    generateBubbles = () => {
        const ts = new Date().getTime();
        for (let i = 0; i <= this.BUBBLES_POPULATION_STEP_COUNT; i++) {
            this.bubbles.set(`${ts}-${i}`, this.createBubble());
        }
    };

    draw() {
        this.ctx.clearRect(0, 0, this.state.CANVAS_WIDTH, this.state.CANVAS_HEIGHT);
        this.drawMainCircle();
        this.generateBubbles();
        const deleteBubbles = [];
        this.bubbles.forEach((bubble, key) => {
            if (bubble.r <= 0.1) {
                deleteBubbles.push(key);
            } else {
                this.drawBubble(bubble, key);
            }
        });
        deleteBubbles.forEach(bubbleKey => {
            this.bubbles.delete(bubbleKey);
        });
        // this.props.text && this.drawText();
        this.animationId = requestAnimationFrame(this.draw);
    };

    render() {
        const { isActive, isAvailable } = this.props;
        return (
            <canvas
                ref={this.canvasRef}
                className={`bubble ${isActive ? 'bubble--active' : ''} ${isAvailable ? 'bubble--available' : ''}`}
                style={{ zIndex: 2, position: 'relative'}}
            />
        );
    }
}

Bubble.propTypes = {
    colors: PT.array,
    width: PT.number,
    height: PT.number,
    isAnimated: PT.bool,
    text: PT.string,
    fontFamily: PT.string,
    fontWeight: PT.number
};
