import React, { useState, useEffect, FC, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransition as CSST } from 'react-transition-group';
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import SearchPageInput from "@components/SearchPageInput/SearchPageInput"
import GlobalSearchResult from "@components/GlobalSearchResult/GlobalSearchResult"
import { addNotification } from '@actions/notifications.actions';
import "./GlobalSearch.scss"
import { deepCopy } from "@core/old_helpers";
import apiConfig from "@core/apiConfig";
import Loader from "@components/Loader/Loader";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import { Routes } from '@routes';
import { useNavigate } from "react-router-dom";
import { iPermissions, Permissions } from "@models/Permissions";
import { useSearchResults } from "@apiHooks/useSearchResults";
import { ID, ReportFileExtension } from "@models";
import { iSearchResultsSection, SearchResults } from "@models/SearchResults";
import { iNotification } from "@models/Noification";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import http from "@core/http";
import { ExportTypeEnum } from '@models/ExportTypeEnum';

let request = {
    abort: () => {
    },
    then: () => {
    },
};

interface iSearchResultsMenuItem {
    title: string;
    path: string;
    id: ID;
}

const titleMenu: iSearchResultsMenuItem[] = [
    {
        title: 'profiles',
        path: '/food-profiles',
        id: 'profiles',
    },
    {
        title: 'top trends',
        path: '/toptrends',
        id: 'topTrends',
    },
    {
        title: 'reports',
        path: '/reports',
        id: 'reports',
    },
    {
        title: 'instant charts',
        path: '/instant-charts',
        id: 'instantCharts',
    },

];


const GlobalSearch: FC = () => {
    let history = useNavigate();
    /* @ts-ignore */
    const countryCode = useSelector(state => state.countries.current);
    const dispatch = useDispatch();
    const pushNotification = (notification: iNotification) => dispatch(addNotification(notification));
    const permissions: iPermissions = useSelector(currentCountryWithPermissionsSelector) || new Permissions();

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItemTypeId, setSelectedItemTypeId] = useState<ID | null>(null);
    const [isShown, setIsShown] = useState(false);

    const searchResults = useSearchResults(countryCode, searchQuery);

    const { isLoading, data } = searchQuery.trim() ? searchResults : { isLoading: false, data: new SearchResults() };

    const onChangeDebounced = useDebouncedCallback((q: string) => {
        setSearchQuery(q);
    }, 300);

    useEffect(() => {
        if (searchQuery) {
            history({
                pathname: Routes.Home,
                search: `q=${searchQuery}`,
            })
        }
    }, [searchQuery]);

    const {
        hasGlobalSearchAllMenu,
        hasFoodProfile,
        hasRtuReport,
        hasInsertChart
    } = permissions;

    let items: iSearchResultsMenuItem[] = deepCopy(titleMenu);

    if (!hasRtuReport) {
        items = items.filter(({ id }) => id !== 'reports');
    }
    if (!hasFoodProfile) {
        items = items.filter(({ id }) => id !== 'profiles');
    }
    if (!hasInsertChart) {
        items = items.filter(({ id }) => id !== 'instantCharts');
    }

    useEffect(() => {
        setIsShown(true);
    }, []);

    useEffect(() => {
        setSelectedItemTypeId(null);
    }, [isLoading]);

    const filteredData: iSearchResultsSection[] = useMemo(() => {
        if (!!selectedItemTypeId) {
            return data.data.filter(elem => elem.id === selectedItemTypeId);
        }
        return data.data;
    }, [data, selectedItemTypeId]);

    const onEnter = () => {
        if (request) request.abort();

        if (searchQuery.trim() !== '') {
            history(`${Routes.byo}?words=${searchQuery}`);
        }
    };

    const downloadSummaryReport = (reportId: ID, eTypeOfExport: ExportTypeEnum) => {
        const msgId = Date.now();
        pushNotification({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('export report', { reportId, eTypeOfExport, countryCode })
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                id: msgId,
            });
        });
    };

    const downloadCategoryReport = (reportId: ID, eTypeOfExport: ReportFileExtension) => {
        const msgId = Date.now();
        pushNotification({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('category report', { reportId, eTypeOfExport, countryCode })
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                id: msgId,
            });
        });
    };

    return (
        <div className="GlobalSearch">
            <CSSTransition in={isShown} classNames="appears-long" timeout={1000} unmountOnExit>
                <div className="GlobalSearch__bg-container">
                    <video muted autoPlay loop className="GlobalSearch__bg">
                        <source src="/bg.mp4" type="video/mp4"/>
                    </video>
                </div>
            </CSSTransition>
            <CSST
                in={isShown}
                classNames={`appears-xx-5`}
                timeout={1500}
                unmountOnExit
            >
                <div className="GlobalSearch__column GlobalSearch__column--input-container">
                    <SearchPageInput
                        onEnter={onEnter}
                        onChange={onChangeDebounced}
                    />
                </div>
            </CSST>
            <div className="GlobalSearch__column">
                <Loader isShown={isLoading} noBg isWhite/>
                <div>
                    {filteredData.map((category, index) => {
                        return (
                            <GlobalSearchResult
                                downloadSummaryReport={downloadSummaryReport}
                                downloadCategoryReport={downloadCategoryReport}
                                itemTypeId={category.id}
                                items={category.items}
                                title={category.title}
                                isOpened={selectedItemTypeId === category.id}
                                handleShowMore={() => setSelectedItemTypeId(category.id)}
                                handleHide={() => setSelectedItemTypeId(null)}
                            />
                        );
                    })}
                </div>
                {hasGlobalSearchAllMenu && (
                    <div className="GlobalSearch__nav-container">
                        <div className="GlobalSearch__nav">
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <div className="GlobalSearch__title">
                                    Discover what's popular, growing, or declining
                                </div>
                            </CSST>
                            {items.map((el, idx) =>
                                (
                                    <CSST
                                        key={el.id}
                                        in={searchQuery.length === 0 && isShown}
                                        classNames={`appears-x-${idx + 5}`}
                                        timeout={1500}
                                        unmountOnExit
                                    >
                                        <Link to={el.path} className="GlobalSearch__nav-item">{el.title}</Link>
                                    </CSST>
                                )
                            )}

                        </div>
                    </div>
                )}
                {!hasGlobalSearchAllMenu && (
                    <div className="GlobalSearch__nav-container--with-more">
                        <div className="GlobalSearch__nav">
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <Link
                                    to={"/toptrends"}
                                    className="GlobalSearch__nav-item GlobalSearch__nav-item--top"
                                >
                                    Top trends
                                </Link>
                            </CSST>
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <Link
                                    to={"/toptrends?categoryId=3"}
                                    className="GlobalSearch__nav-item"
                                >
                                    Proteins
                                </Link>
                            </CSST>
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <Link
                                    to={"/toptrends?categoryId=1"}
                                    className="GlobalSearch__nav-item"
                                >
                                    Sauces
                                </Link>
                            </CSST>
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <Link
                                    to={"/toptrends?categoryId=5"}
                                    className="GlobalSearch__nav-item"
                                >
                                    Cheese
                                </Link>
                            </CSST>
                            <CSST in={searchQuery.length === 0 && isShown} classNames="appears-x-4" timeout={1500}
                                  unmountOnExit>
                                <Link
                                    to={"/toptrends"}
                                    className="GlobalSearch__nav-item"
                                >
                                    ... And more
                                </Link>
                            </CSST>

                        </div>

                    </div>
                )}
            </div>
        </div>
    )
};

export default GlobalSearch;