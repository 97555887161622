import React, { useContext, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import PT from "prop-types";
import "./CheckboxInput.scss";
import Flag from "../Flag/Flag";
import { IconArrowRight, IconCheck } from "@icons";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";

const CheckboxInput = (
    {
        isChecked,
        isPartialChecked = false,
        onChange,
        onClickIcon = () => {},
        labelText,
        labelClass = '',
        isSmall = false,
        isMedium = false,
        isBold = false,
        isUnderlined = false,
        noIcon = false,
        onClick,
        hasArrowIcon = false,
        stopPropagation,
        isArrowDown = false,
        isBlueCheck = false,
        disabled = false,
        className = '',
        isRadioBtn = false,
        isCutLabel = false,
        labelIcon = null,
        showModifierIcon = false,
        modifiers = [],
        onMouseEnter = undefined,
        onMouseLeave = undefined,
    }
) => {
    const { getRem } = useContext(GlobalContext)
    const checkboxRef = useRef(null);

    const handleClickCheckbox = event => {
        stopPropagation && event.stopPropagation();
        onChange();
    };
    const handleIconClick = event => {
        stopPropagation && event.stopPropagation();
        onClick ? onClick() : onChange();
    };

    const classList = new Set([
        "checkbox-input",
        className,
        isChecked ? "checkbox-input--checked" : "",
        isSmall ? "checkbox-input--small" : "",
        isMedium ? "checkbox-input--medium" : "",
        isBold ? "checkbox-input--bold" : "",
        isUnderlined ? "checkbox-input--underlined" : "",
        disabled ? "checkbox-input--disabled" : "",
        ...modifiers.map(i => `checkbox-input--${i}`),
    ]);

    const labelClassList = new Set([
        "checkbox-input__label",
        labelClass ? labelClass : '',
        isCutLabel ? 'checkbox-input__label-cut' : "",
        showModifierIcon ? "checkbox-input__label--has-modifier" : ""
    ]);

    const successIcon = <Icon type='small' className={cn("checkbox-input__icon", isBlueCheck && "is-blue")}><IconCheck/></Icon>

    let icon;

    if (isPartialChecked) {
        icon = (
            <span
                className={`
                    checkbox-input__fake-check-small
                    ${isBlueCheck ? 'checkbox-input__fake-check-small--blue' : 'test'}
                `}
            />
        );
    }

    if (isChecked) {
        icon = successIcon;
    }

   if (isRadioBtn) {
        icon = (
            <span
                className={`
                    checkbox-input__fake-check-small--radio
                    ${isBlueCheck ? 'checkbox-input__fake-check-small--radio-blue' : ''}
                `}
            />
        );
    }

    const classListCheckBox = new Set([
       'checkbox-input__fake-check',
       isRadioBtn ? 'checkbox-input__fake-check--radio' : '',
       isChecked && isBlueCheck ? `checkbox-input__fake-check--blue${isRadioBtn ? '-radio' : ''}` : '',
    ]);

    const handleChangeOnIcon = (event) => {
        handleClickCheckbox(event);
        onClickIcon(event);
    }

    return (
        <div
            className={Array.from(classList).join(" ")}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <input
                ref={checkboxRef}
                type="checkbox"
                onChange={() => {
                }}
                checked={isChecked}
                className="checkbox-input__real-check"
                disabled={disabled}
            />
            {!noIcon && (
                <button
                    className={Array.from(classListCheckBox).join(' ')}
                    onClick={handleChangeOnIcon}
                >
                    {icon}
                </button>
            )}
            {labelIcon && (
                <div className="checkbox-input__label-icon">
                    <Flag height={getRem(24)} compact component={labelIcon}/>
                </div>
            )}
            <button onClick={handleClickCheckbox} className={Array.from(labelClassList).join(" ")}>
                {labelText}
            </button>
            {hasArrowIcon && (
                <button
                    onClick={handleIconClick}
                    className={`checkbox-input__arrow${isArrowDown ? " checkbox-input__arrow--down" : ""}`}
                >
                    <Icon type="small" className="checkbox-input__arrow-icon">
                        <IconArrowRight/>
                    </Icon>
                </button>
            )}
        </div>
    );
};

CheckboxInput.propTypes = {
    isChecked: PT.bool,
    onChange: PT.func,
    labelText: PT.string,
    isSmall: PT.bool,
    onClick: PT.func,
    stopPropagation: PT.bool,
    isArrowDown: PT.bool,
    itemId: PT.number
};

export default connect((state) => ({
    isThemeLightEnable: state.app.isThemeLightEnable,
}))(CheckboxInput);
