import React, { useReducer, useState, useEffect, useContext, useMemo } from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import { BUBBLE_OPTIONS } from "./filtersContainer.data";
import Modal from "@components/Modal/Modal";
import Bubble from "@components/Bubble/Bubble";
import VenuesForm from "@components/VenuesForm/VenuesForm";
import MenuItems from "@components/MenuItems/MenuItems";
import Attributes from "@components/Attributes/Attributes";
import { filtersModelSelector } from "@selectors/filters.selectors";
import "./FiltersContainer.scss";
import { apply as applyFilters } from "@actions/filters.actions";
import { getFiltersValues } from "@core/filters.helpers";
import { deepEqual, getRadians } from "@core/old_helpers";
import FiltersPopupFooterBtns from "./FiltersPopupFooterBtns";
import AdditionalFilters from "./AdditionalFilters";
import { currentCurrencySelector } from "@selectors/countries.selector";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import { IconArrowLeft, IconArrowRight, IconClose } from "@icons";
import Icon from "@components/Icon/Icon";
import { GlobalContext } from "@components/App/App.context";

const oneThird = 1 / 3;

const FiltersContainer = (
    {
        shown,
        onClose,
        filters,
        applyFilters,
        keywords,
        restaurants,
        currentCurrency,
        permissions = {},
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const [localFilters, setLocalFilters] = useReducer(reducer, initialLocalFilters);
    const [radius, setRadius] = useState(window.innerWidth * 0.8 / 2);
    const [isAdditionalFiltersOpen, setIsAdditionalFiltersOpen] = useState(false);
    const [isFiltersBeenChanged, setIsFiltersBeenChanged] = useState(false);
    const [currentFilter, setCurrentFilter] = useState(3);
    const [rotate, setRotate] = useState(-oneThird);
    const [isAnimated, setIsAnimated] = useState(true);
    const { hasFiltersAll } = permissions;

    useEffect(() => {
        setIsFiltersBeenChanged(!deepEqual(filters, localFilters));
    }, [filters, localFilters]);

    useEffect(() => {
        if (shown) {
            setLocalFilters(filters);
            setIsAdditionalFiltersOpen(false);
        }
    }, [shown, hasFiltersAll, filters]);
    useEffect(() => {
        if (hasFiltersAll) {
            handleBubbleClick(3);
        } else {
            handleBubbleClick(1);
        }
    }, [hasFiltersAll]);

    const apply = () => {
        applyFilters(getFiltersValues(localFilters));
        onClose();
    };

    const handleChange = (section, fieldName, newValue) => {
        setLocalFilters({
            ...localFilters,
            [section]: {
                ...localFilters[section],
                [fieldName]: newValue,
            }
        });
    };

    const handleCheckboxGroupChange = (section, fieldName, id, isGroup) => {
        const field = localFilters[section][fieldName];
        let newValue;
        if (id === 'all') {
            newValue = field.map(option => ({ ...option, isChecked: !field.every(option => option.isChecked) }));
        } else if (isGroup) {
            const groupValue = !field.filter(option => option.groupId === id).every(option => option.isChecked);
            newValue = field.map(option => ({
                ...option,
                isChecked: option.groupId === id ? groupValue : option.isChecked,
            }));
        } else {
            newValue = field.map(option => ({
                ...option,
                isChecked: option.id === id ? !option.isChecked : option.isChecked,
            }));
        }
        handleChange(section, fieldName, newValue);
    };

    const handleCheckAllMenuItems = () => {
        const isAllChecked = localFilters.menuItems.appetizers.every(option => option.isChecked) &&
            localFilters.menuItems.entrees.every(option => option.isChecked) &&
            localFilters.menuItems.sides.every(option => option.isChecked) &&
            localFilters.menuItems.desserts.every(option => option.isChecked) &&
            localFilters.menuItems.beverages.every(option => option.isChecked);
        setLocalFilters({
            ...localFilters,
            menuItems: {
                ...localFilters.menuItems,
                appetizers: localFilters.menuItems.appetizers.map(option => ({ ...option, isChecked: !isAllChecked })),
                entrees: localFilters.menuItems.entrees.map(option => ({ ...option, isChecked: !isAllChecked })),
                sides: localFilters.menuItems.sides.map(option => ({ ...option, isChecked: !isAllChecked })),
                desserts: localFilters.menuItems.desserts.map(option => ({ ...option, isChecked: !isAllChecked })),
                beverages: localFilters.menuItems.beverages.map(option => ({ ...option, isChecked: !isAllChecked })),
            },
        });
    };

    const addItems = (section, fieldName, items) => {

        const field = localFilters[section][fieldName];
        handleChange(section, fieldName, [...field, ...items]);
    };

    const removeItem = (section, fieldName, itemName) => {
        const field = localFilters[section][fieldName];
        const itemIndex = field.findIndex(item => item.name.toLowerCase() === itemName.toLowerCase());

        handleChange(section, fieldName, [
            ...field.slice(0, itemIndex),
            ...field.slice(itemIndex + 1)
        ]);
    };

    const handleWindowResize = () => setRadius(window.innerWidth * 0.9 / 2);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    function handleBubbleClick(bubbleInx, event) {
        if (event) event.stopPropagation();

        if (bubbleInx !== currentFilter) {
            const diff = bubbleInx - currentFilter;
            setIsAnimated(false);
            setTimeout(() => {
                setIsAnimated(true);
            }, 800);

            if (Math.abs(diff) === 1) {
                setRotate(rotate + oneThird * diff);
            } else if (Math.abs(diff) === 2) {
                setRotate(rotate - oneThird * diff / 2);
            }
            setCurrentFilter(bubbleInx);
        }
    }

    const handleArrowLeftClick = (event) => {
        handleBubbleClick(currentFilter === 1 ? 3 : currentFilter - 1, event);
    };
    const handleArrowRightClick = (event) => {
        handleBubbleClick(currentFilter === 3 ? 1 : currentFilter + 1, event);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleWindowKeydown);
        return () => {
            window.removeEventListener('keydown', handleWindowKeydown);
        }
    }, [currentFilter]);

    const handleWindowKeydown = (event) => {
        if (!hasFiltersAll) return;
        if (event.target?.nodeName === 'INPUT') return;

        if (event.code === 'ArrowLeft') {
            handleArrowLeftClick(event);
        } else if (event.code === 'ArrowRight') {
            handleArrowRightClick(event);
        }
    };
    const getPlanetTransform = (index, angle) => {
        return ''
        + ( `translate(${Math.cos(getRadians(angle)) * radius}px, ${-Math.sin(getRadians(angle)) * radius}px) ` )
        + ( `rotate(${-rotate}turn) ` )
        + ( 'scaleY(5) ' )
        + ( currentFilter !== index ? 'scale(0.5) ' : '')
        + ( currentFilter === index ? `translate(-35vw, calc(50vh - 17vw)) ` : 'translate(0, 0) ' )
    };

    return (
        <Modal closeModal={onClose} shown={shown} closeOnOutsideClick={false} isOverFooter>
            <div className="filters-popup">
                <div className="Planets">
                    <div
                        className="ellipse-line"
                        style={{
                            opacity: `${hasFiltersAll ? '' : '0'}`,
                        }}
                    />
                    <div
                        className="ellipse"
                        style={{
                            transform: ''
                                + `translateY(-39%)`
                                + 'scaleY(0.2) '
                                + `rotate(${rotate}turn)`
                            ,
                            borderColor: `${hasFiltersAll ? '' : 'transparent'}`,
                        }}
                    >
                        <div
                            className={`planet ${currentFilter === 1 ? 'is-active' : ''}`}
                            onClick={(event) => handleBubbleClick(1, event)}
                            style={{
                                transform: getPlanetTransform(1, 270),
                                marginTop: `${hasFiltersAll ? '' : '-33vw'}`,
                            }}
                        >
                            <div className={`planet__popup ${currentFilter === 1 ? 'planet__popup--shown' : ''}`}>
                                {isAdditionalFiltersOpen ? (
                                    <>
                                        <div className="planet__popup-back">
                                            <button
                                                className="planet__popup-back-btn"
                                                onClick={() => {
                                                    setIsAdditionalFiltersOpen(false)
                                                }}
                                            >
                                                back
                                            </button>
                                            {/*<ArrowLeftIcon/>*/}
                                        </div>
                                        <AdditionalFilters
                                            addKeywords={(words) => addItems('menuItems', 'words', words)}
                                            removeKeyword={(...args) => removeItem('menuItems', 'words', ...args)}
                                            keywords={keywords}
                                            words={localFilters.menuItems.words}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="planet__popup-close">
                                            <Icon>
                                                <IconClose
                                                    onClick={onClose}
                                                />
                                            </Icon>
                                        </div>
                                        {Object.keys(localFilters.menuItems).length > 0 && (
                                            <MenuItems
                                                setIsAdditionalFiltersOpen={setIsAdditionalFiltersOpen}
                                                data={localFilters.menuItems}
                                                customRestaurants={localFilters.venues.restaurants}
                                                restaurants={restaurants}
                                                addRestaurants={(restaurant) => addItems('venues', 'restaurants', [restaurant])}
                                                removeRestaurant={(index) => removeItem('venues', 'restaurants', index)}
                                                handleChange={(...args) => handleChange('menuItems', ...args)}
                                                handleCheckAll={handleCheckAllMenuItems}
                                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('menuItems', ...args)}
                                                currentCurrency={currentCurrency}
                                                hasCustomRestaurantsField={!hasFiltersAll}
                                            />
                                        )}
                                    </>
                                )}
                                <FiltersPopupFooterBtns
                                    isFiltersBeenChanged={isFiltersBeenChanged}
                                    resetFilters={onClose}
                                    applyFilters={apply}
                                />
                            </div>
                            <Bubble
                                {...BUBBLE_OPTIONS[1]}
                                isAnimated={isAnimated}
                                isActive={currentFilter === 1}
                                isAvailable={currentFilter === 0}
                                getRem={getRem}
                            />
                            <div className="planet__title">
                                {hasFiltersAll ? BUBBLE_OPTIONS[1].text : 'FILTERS'}
                            </div>
                        </div>
                        {hasFiltersAll && (
                            <>
                                <div
                                    className={`planet ${currentFilter === 2 ? 'is-active' : ''}`}
                                    onClick={(event) => handleBubbleClick(2, event)}
                                    style={{
                                        transform: getPlanetTransform(2, 30),
                                    }}
                                >
                                    <div className={`planet__popup ${currentFilter === 2 ? 'planet__popup--shown' : ''}`}>
                                        <div className="planet__popup-close">
                                            <Icon>
                                                <IconClose
                                                    onClick={onClose}
                                                />
                                            </Icon>
                                        </div>
                                        {Object.keys(localFilters.attributes).length > 0 && (
                                            <Attributes
                                                data={localFilters.attributes}
                                                handleChange={(...args) => handleChange('attributes', ...args)}
                                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('attributes', ...args)}
                                            />
                                        )}
                                        <FiltersPopupFooterBtns
                                            isFiltersBeenChanged={isFiltersBeenChanged}
                                            resetFilters={onClose}
                                            applyFilters={apply}
                                        />
                                    </div>
                                    <Bubble
                                        {...BUBBLE_OPTIONS[2]}
                                        isAnimated={isAnimated}
                                        isActive={currentFilter === 2}
                                        isAvailable={currentFilter === 0}
                                        getRem={getRem}
                                    />
                                    <div className="planet__title">
                                        {BUBBLE_OPTIONS[2].text}
                                    </div>
                                </div>
                                <div
                                    className={`planet ${currentFilter === 3 ? 'is-active' : ''}`}
                                    onClick={(event) => handleBubbleClick(3, event)}
                                    style={{
                                        transform: getPlanetTransform(3, 150),
                                    }}
                                >
                                    <div className={`planet__popup ${currentFilter === 3 ? 'planet__popup--shown' : ''}`}>
                                        <div className="planet__popup-close">
                                            <Icon>
                                                <IconClose
                                                    onClick={onClose}
                                                />
                                            </Icon>
                                        </div>
                                        {Object.keys(localFilters.venues).length > 0 && (
                                            <VenuesForm
                                                data={localFilters.venues}
                                                restaurants={restaurants}
                                                addRestaurants={(items) => addItems('venues', 'restaurants', items)}
                                                removeRestaurant={(index) => removeItem('venues', 'restaurants', index)}
                                                handleChange={(...args) => handleChange('venues', ...args)}
                                                handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange('venues', ...args)}
                                                isFiltersBeenChanged={isFiltersBeenChanged}
                                            />
                                        )}
                                        <FiltersPopupFooterBtns
                                            isFiltersBeenChanged={isFiltersBeenChanged}
                                            resetFilters={onClose}
                                            applyFilters={apply}
                                        />
                                    </div>
                                    <Bubble
                                        {...BUBBLE_OPTIONS[3]}
                                        isAnimated={isAnimated}
                                        isActive={currentFilter === 3}
                                        isAvailable={currentFilter === 0}
                                        getRem={getRem}
                                    />
                                    <div className="planet__title">
                                        {BUBBLE_OPTIONS[3].text}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {hasFiltersAll && (
                        <>
                            <Icon
                                type="large"
                                className="Planets__arrow Planets__arrow--left"
                            >
                                <IconArrowLeft onClick={handleArrowLeftClick}/>
                            </Icon>
                            <Icon
                                type="large"
                                className="Planets__arrow Planets__arrow--right"
                            >
                                <IconArrowRight onClick={handleArrowRightClick}/>
                            </Icon>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

const initialLocalFilters = {
    venues: {},
    menuItems: {},
    attributes: {},
};

const reducer = (state, action) => {
    return { ...state, ...action };
};

const mapStateToProps = state => ({
    filters: filtersModelSelector(state),
    keywords: state.keywords.data,
    restaurants: state.filters.activeRestaurantList,
    permissions: currentCountryWithPermissionsSelector(state),
    currentCurrency: currentCurrencySelector(state),
});

const mapDispatchToProps = dispatch => ({
    applyFilters: data => dispatch(applyFilters(data)),

});

FiltersContainer.propTypes = {
    isFiltersPopupShown: PT.bool,
    onClose: PT.func,
    applyFilters: PT.func,
    filters: PT.object,
    keywords: PT.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersContainer);
