import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';
import "./Tiles.styles.scss";

const Tile = ({ isLink, handleClick, name, id, wordsCount }) => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    let Component = 'div';
    const props = {};
    if (isLink) {
        Component = Link;
        props.to = `/byo?instantChartId=${id}&wordsCount=${wordsCount}`;
    } else {
        props.onClick= handleClick;
    }

    return (
        <Component
            onMouseEnter={(event) => {
                setX(event.clientX - event.currentTarget.getBoundingClientRect().left);
                setY(event.clientY - event.currentTarget.getBoundingClientRect().top);
            }}
            onMouseLeave={(event) => {
                setX(event.clientX - event.currentTarget.getBoundingClientRect().left);
                setY(event.clientY - event.currentTarget.getBoundingClientRect().top);
            }}
            className="tile"
            {...props}
        >
            <div
                className="tile__fill"
                style={{
                    top: y,
                    left: x,
                }}
            />
            <div className="tile__text">
                {name}
            </div>
        </Component>
    )
};

const Tiles = (
    {
        data = [],
        zoomLevel,
        handleItemClick,
        isItemLink,
    }
) => {

    const tilesClassList = new Set([
        'tiles',
        zoomLevel > 0 ? `tiles--zoomed-${zoomLevel}` : '',
        zoomLevel === 0 ? 'tiles--zoomed-out' : ''
    ]);

    return (
        <div className={Array.from(tilesClassList).join(' ')}>
            <Scrollbar>
                <div className="tiles__inner">
                    {data.map((item, index) => (
                        <Tile
                            key={index}
                            isLink={isItemLink}
                            {...item}
                            handleClick={() => handleItemClick(index)}
                        />
                    ))}
                </div>
            </Scrollbar>
        </div>
    )
};

export default Tiles;