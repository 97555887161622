import serializeParams from './serializeParams';

const API_PATH = process.env.REACT_APP_API;

class ApiConfig {
    constructor(methods = []) {
        methods.forEach(({ name, url }) => {
            this[name] = `${API_PATH}/${url}`;
        });
    }

    get(name, params = {}) {
        return `${this[name]}?${serializeParams(params)}`
    }

    getWithIds(name, ids, params = {}) {
        return `${this[name]}/${ids.join('/')}?${serializeParams(params)}`
    }

    getWithId(name, id, params = {}) {
        return this.getWithIds(name, [id], params);
    }

}

export default new ApiConfig([
    { name: 'countries', url: 'Country' },
    { name: 'byo', url: 'Byo' },
    { name: 'keywords', url: 'Keyword' },
    { name: 'filters', url: 'ByoFilter' },
    { name: 'byo settings', url: 'ByoSettings' },
    { name: 'top trends', url: 'TopTrends' },
    { name: 'copy table', url: 'TopTrendCopyTable' },
    { name: 'food profiles', url: 'FoodProfiles' },
    { name: 'food profile', url: 'FoodProfile' },
    { name: 'food profile lto', url: 'FoodProfileLTO' },
    { name: 'country subscribe', url: 'SubscribeRequest' },
    { name: 'global search', url: 'GlobalSearch' },
    { name: 'restaurant list', url: 'RestaurantList' },
    { name: 'top trends menu examples', url: 'TopTrendMenuExamples' },
    { name: 'byo menu examples', url: 'ByoMenuExamples' },
    { name: 'instant chart', url: 'InstantCharts' },
    { name: 'rtu reports', url: 'RtuReports' },
    { name: 'instant charts', url: 'InstantCharts' },
    { name: 'export byo menu examples', url: 'ByoMenuExampleExport' },
    { name: 'export top trends', url: 'TopTrendMainExport' },
    { name: 'export top trends menu examples', url: 'TopTrendMenuExampleExport' },
    { name: 'export menu matrix', url: 'ByoMenuMatrixExport' },
    { name: 'export BYO chart', url: 'ByoTrendChartExport' },
    { name: 'export BYO instant chart', url: 'ByoInstantChartReport' },
    { name: 'export BYO instant chart with flavor', url: 'ByoInstantChartReport/WithFlavor' },
    { name: 'export simple matrix', url: 'ByoSimpleMatrixExport' },
    { name: 'export trend details', url: 'ByoTrendDetailExport' },
    { name: 'export long term report', url: 'ByoLongTermTrendExport' },
    { name: 'export report', url: 'RtuReport' },
    { name: 'export food profile', url: 'FoodProfileReport' },
    { name: 'admin access', url: 'admin/Permission/HasAccess' },
    { name: 'admin all keywords', url: 'admin/Keyword/GetAll' },
    { name: 'admin keyword', url: 'admin/KeyWord' },
    { name: 'admin keyword search', url: 'admin/KeyWord/Search' },
    { name: 'admin keyword image', url: 'admin/Image' },
    { name: 'admin keyword images', url: 'admin/Image/UploadFiles' },
    { name: 'admin keyword setting data', url: 'admin/KeyWordFilter' },
    { name: 'admin keyword names', url: 'admin/KeyWord/GetNames' },
    { name: 'admin keyword export', url: 'admin/KeyWord/Export' },
    { name: 'admin instant charts', url: 'admin/InstantCharts' },
    { name: 'admin instant charts categories', url: 'admin/InstantCharts/GetCategories' },
    { name: 'admin instant chart save', url: 'admin/InstantCharts/SaveChart' },
    { name: 'admin instant chart category save', url: 'admin/InstantCharts/SaveCategory' },
    { name: 'admin instant chart subcategory save', url: 'admin/InstantCharts/SaveSubCategory' },
    { name: 'admin reports', url: 'admin/RtuReports' },
    { name: 'admin reports child reports', url: 'admin/RtuReports/childReports' },
    { name: 'admin reports companies', url: 'admin/RtuReports/companies' },
    { name: 'admin report add', url: 'admin/RtuReports/add' },
    { name: 'admin report edit', url: 'admin/RtuReports/edit' },
    { name: 'custom restaurant lists', url: 'CustomRestaurantLists' },
    { name: 'request access flavor', url: 'SubscribeRequest/Flavor' },
    { name: 'admin category reports', url: 'Admin/CategoryReports' },
    { name: 'category report', url: 'CategoryReport' },
    { name: 'category reports', url: 'CategoryReports' },
    { name: 'admin dictionaries', url: 'Admin/Dictionaries' },
    { name: 'admin instant charts export', url: 'Admin/InstantCharts/Export' },
    { name: 'categories fastest growing', url: 'TopTrendKeywords' },
]);
