export interface iPermissions {
    hasFoodProfile: boolean;
    hasRtuReport: boolean;
    hasInsertChart: boolean;
    hasFiltersAll: boolean;
    hasLongTermTrend: boolean;
    hasNewItemsOnly: boolean;
    hasReportData: boolean;
    hasChart: boolean;
    hasGlobalSearchAllMenu: boolean;
    isTrial: boolean;
}

export class Permissions implements iPermissions {
    hasFoodProfile = false;
    hasRtuReport = false;
    hasInsertChart = false;
    hasFiltersAll = false;
    hasLongTermTrend = false;
    hasNewItemsOnly = false;
    hasReportData = false;
    hasChart = false;
    hasGlobalSearchAllMenu = false;
    isTrial = false;
}