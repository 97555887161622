import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from "react-redux"
import { matchPath, Navigate as Redirect, useLocation } from 'react-router-dom';
import ScrollBar from 'react-scrollbars-custom';
import { Helmet } from 'react-helmet-async';
import './Page.styles.scss';
import { PlatformNavigation, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import PlatformHeader from "@containers/PlatformHeader/PlatformHeader";
import { ADMIN_ROUTES } from "@routes";
import cn from "classnames";

const PREFIX_TITLE = "Menu Trends by Datassential";

function Page({
    children,
    isWrapped = false,
    title = '',
}) {
    const location = useLocation();
    const countryCode = useSelector(state => state.countries.current);
    const [redirectLink, setRedirectLink] = useState('');
    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');

    const isAdminRoute = useMemo(
        () => ADMIN_ROUTES.some(route => matchPath(route, location.pathname)),
        [location.pathname]
    );

    useEffect(() => {
        if (!countryCode) setRedirectLink('/');
    }, []);

    const wrapperClass = isWrapped
        ? "wrapper"
        : "";

    return (
        <div className={cn("Page", isAdminRoute && "is-admin-route")}>
            <ScrollBar
                className="Page__scroll"
                noScrollX
            >
                {isPlatformEnabled && !isAdminRoute && <PlatformNavigation className="PlatformNavigation"/>}
                {isPlatformEnabled && !isAdminRoute && <PlatformHeader/>}
                <Helmet title={`${PREFIX_TITLE}${title ? ` | ${title}` : ``}`}/>
                <div className={`Page__${wrapperClass}`}>
                    {children}
                </div>
            </ScrollBar>
            {redirectLink && <Redirect to={redirectLink}/>}
        </div>
    );
}

export default Page;