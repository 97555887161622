import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PT from "prop-types";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import Accordion from "../Accordion/Accordion";
import AutoWidthInput from "../AutoWidthInput/AutoWidthInput";
import "./VenuesForm.scss";
import MultipleInput from "../MultipleInput/MultipleInput";
import {setData as watcherFiltersChanging} from "@actions/filters.actions";

const VenuesForm = (
    {
        data,
        handleChange,
        handleCheckboxGroupChange,
        restaurants,
        addRestaurants,
        removeRestaurant,
        watcherFiltersChanging,
        fullLayout
    }
) => {

    const {
        segments = [],
        regions = [],
        cuisines = [],
        hostedLocations = [],
        minUnits,
        maxUnits
    } = data;


    useEffect(() => {
        watcherFiltersChanging({
            isVenuesFilterBeenChanged: segments.every(e => e.isChecked) && regions.every(e => e.isChecked) && cuisines.every(e => e.isChecked)
        });
    }, [segments, regions, cuisines]);

    const [isUnitsCountOpen, setIsUnitsCountOpen] = useState(false);
    const [isUnitsCountApplied, setIsUnitsCountApplied] = useState(false);

    useEffect(() => {
        if (minUnits !== '1' || maxUnits !== null) {
            setIsUnitsCountApplied(true);
        }
        return () => setIsUnitsCountApplied(false);
    }, [minUnits, maxUnits]);

    function formatValue(defaultValue, str) {
        const num = str
            .replace(/[^0-9]/g, '')
            .replace(/^0(\d?)/, '$1');

        return num || defaultValue;
    }

    return (
        <div className={`venues-form ${fullLayout ? 'venues-form--is-full' : ''}`}>
            <div className="venues-form__header">

                {segments.length > 0 && (
                    <div className="venues-form__col-1">
                        <div className="venues-form__item">
                            <CheckboxInput
                                isBlueCheck
                                onChange={() => handleCheckboxGroupChange('segments', 'all')}
                                labelText="Segments"
                                isChecked={segments.every(option => option.isChecked)}
                                isPartialChecked={segments.some(option => option.isChecked) && !segments.every(option => option.isChecked)}

                            />
                        </div>
                    </div>
                )}
                {hostedLocations.length > 0 && (
                    <div className="venues-form__col-1">
                        <div className="venues-form__item">
                            <CheckboxInput
                                isBlueCheck
                                onChange={() => handleCheckboxGroupChange('hostedLocations', 'all')}
                                labelText="Segments"
                                isChecked={hostedLocations.every(option => option.isChecked)}
                                isPartialChecked={hostedLocations.some(option => option.isChecked) && !hostedLocations.every(option => option.isChecked)}
                            />
                        </div>
                    </div>
                )}
                {regions.length > 0 && (
                    <div className="venues-form__col-2">
                        <div className="venues-form__item">
                            <CheckboxInput
                                isBlueCheck
                                onChange={() => handleCheckboxGroupChange('regions', 'all')}
                                labelText="Regions"
                                isChecked={regions.every(option => option.isChecked)}
                                isPartialChecked={regions.some(option => option.isChecked) && !regions.every(option => option.isChecked)}

                            />
                        </div>
                    </div>
                )}
                {cuisines.length > 0 && (
                    <div className="venues-form__col-3">
                        <div className="venues-form__item">
                            <CheckboxInput
                                isBlueCheck
                                onChange={() => handleCheckboxGroupChange('cuisines', 'all')}
                                labelText="Cuisines"
                                isChecked={cuisines.every(option => option.isChecked)}
                                isPartialChecked={cuisines.some(option => option.isChecked) && !cuisines.every(option => option.isChecked)}

                            />
                        </div>
                    </div>
                )}
                <div className="venues-form__col-4">
                    <div className="venues-form__input-header">
                        Specific restaurant
                    </div>
                </div>

            </div>
            <div className="venues-form__content">
                {segments.length > 0 && (
                    <div className="venues-form__col-1">
                        {segments.map((option, index) => (
                            <div key={index} className="venues-form__item">
                                <CheckboxInput
                                    isSmall
                                    onChange={() => handleCheckboxGroupChange('segments', option.id)}
                                    labelText={option.name}
                                    isChecked={option.isChecked}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {hostedLocations.length > 0 && (
                    <div className="venues-form__col-1">
                        {hostedLocations.map((option, index) => (
                            <div key={index} className="venues-form__item">
                                <CheckboxInput
                                    isSmall
                                    onChange={() => handleCheckboxGroupChange('hostedLocations', option.id)}
                                    labelText={option.name}
                                    isChecked={option.isChecked}
                                />
                            </div>
                        ))}
                    </div>
                )}

                {regions.length > 0 && (
                    <div className="venues-form__col-2">
                        {regions.map((option, index) => (
                            <div key={index} className="venues-form__item">
                                <CheckboxInput
                                    isSmall
                                    onChange={() => handleCheckboxGroupChange('regions', option.id)}
                                    labelText={option.name}
                                    isChecked={option.isChecked}
                                />
                            </div>
                        ))}
                    </div>
                )}

                {cuisines.length > 0 && (
                    <div className="venues-form__col-3">
                        <div className="venues-form__accordion-container">
                            <Accordion
                                data={cuisines}
                                handleChange={(...args) => handleCheckboxGroupChange('cuisines', ...args)}
                            />
                        </div>
                    </div>
                )}

                <div className="venues-form__col-4">
                    <div className="venues-form__input-container">
                        <MultipleInput
                            submittedItems={data.restaurants}
                            onSubmit={addRestaurants}
                            removeItem={removeRestaurant}
                            keywords={restaurants}
                            modifiers={["filters", ...fullLayout ? ['light', 'medium'] : []]}
                            onlyFromList
                            placeholder="Type a restaurant"
                        />
                    </div>
                </div>

            </div>
            <div className="venues-form__footer">
                <button
                    className={`venues-form__footer-btn venues-form__footer-btn--${isUnitsCountOpen ? 'hidden' : 'visible'}`}
                    onClick={() => {
                        setIsUnitsCountOpen(true);
                    }}
                >
                    <div className="venues-form__footer-filter">Filter by unit count</div>
                    <div className={`venues-form__footer-range venues-form__footer-range--${isUnitsCountApplied ? 'visible' : 'hidden'}`}>
                        You have <span>{minUnits}-{maxUnits === null ? 'any' : maxUnits}</span> range applied
                    </div>
                </button>
                <div className={`venues-form__footer-text venues-form__footer-text--${isUnitsCountOpen ? 'visible' : 'hidden'}`}>
                    only places between&nbsp;
                    <AutoWidthInput
                        defaultValue="1"
                        value={minUnits}
                        onChange={({ target }) => handleChange('minUnits', formatValue(null, target.value))}
                        onBlur={({ target }) => handleChange('minUnits', formatValue('1', target.value))}
                        clearDefaultValueOnFocus
                    />
                    &nbsp;and&nbsp;
                    <AutoWidthInput
                        defaultValue="any"
                        value={maxUnits}
                        onChange={({ target }) => handleChange('maxUnits', formatValue(null, target.value))}
                        clearDefaultValueOnFocus
                    />
                    &nbsp;units
                </div>
            </div>
        </div>
    );
};

VenuesForm.propTypes = {
    data: PT.shape({
        segments: PT.array,
        regions: PT.array,
        dayParts: PT.array,
        cuisines: PT.array,
        minUnits: PT.oneOfType([PT.number, PT.string]),
        maxUnits: PT.oneOfType([PT.number, PT.string])
    }),
    changeMinUnits: PT.func,
    changeMaxUnits: PT.func,
    toggleItem: PT.func,
    toggleGroup: PT.func
};

export default connect(state => ({
}), {watcherFiltersChanging})(VenuesForm);
