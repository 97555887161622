import { useCallback, useMemo, useState } from "react";
import Page from "@components/Page/Page";
import { usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import GlobalSearch from "@pages/GlobalSearch/GlobalSearch";
import './Home.scss';
import { TOP_TRENDS_CATEGORIES } from "@reducers/topTrends.reducer";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { currentCountrySelector } from "@selectors/countries.selector";
import useCategoriesFastestGrowing from "@apiHooks/useCategoriesFastestGrowing";
import SlideContent from "@pages/Home/SlideContent";


const Home = () => {
    const currentCountry = useSelector(currentCountrySelector);
    const { data} = useCategoriesFastestGrowing(currentCountry);
    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');

    const categories = useMemo(() => {
        const result = [...TOP_TRENDS_CATEGORIES];
        result.sort(() => Math.random() - 0.5);

        return result;
    }, []);

    const mappedData = useMemo(
        () => {
            if (data.length === 0) return categories.map((category) => ({
                ...category,
                keywords: [],
            }));

            return categories.map((category) => {
                const categoryData = data.find((d) => d.id === category.id);

                return {
                    ...category,
                    keywords: categoryData?.keywords || [],
                }
            });
        },
        [categories, data]
    );

    const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);

    const nextSlide = useCallback(
        () => {
            setActiveCategoryIndex((prev) => {
                return (prev + 1) % categories.length;
            })
        },
        [categories]
    );

    const prevSlide = useCallback(
        () => {
            setActiveCategoryIndex((prev) => {
                return (prev - 1 + categories.length) % categories.length;
            })
        },
        [categories]
    );


    if (!isPlatformEnabled) return <GlobalSearch/>;
    return (
        <Page title="">
            <div className="Home">
                <div className="HomeSlider">
                    {mappedData.map((category, index) => (
                        <CSSTransition
                            key={category.id}
                            in={index === activeCategoryIndex}
                            timeout={1000}
                            unmountOnExit={true}
                            classNames="HomeSlider"
                        >
                            <SlideContent
                                category={category}
                                prev={prevSlide}
                                next={nextSlide}
                            />
                        </CSSTransition>
                    ))}
                </div>
            </div>
        </Page>
    );
}

export default Home;