import React from "react";

const FiltersPopupFooterBtns = (
    {
        resetFilters,
        applyFilters,
        isFiltersBeenChanged,
    }
) => {
    return (
        <div className="planet__popup-btns">
            <button onClick={resetFilters} className="reset-filters">
                Cancel
            </button>
            <button
                onClick={applyFilters}
                disabled={!isFiltersBeenChanged}
                className={`apply-filters ${isFiltersBeenChanged ? 'apply-filters--active' : ''}`}
            >
                Apply
            </button>
        </div>
    )
};

export default FiltersPopupFooterBtns;