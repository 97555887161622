import React, { forwardRef, memo, useContext, useMemo } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
    currentCurrencySelector,
    currentCurrencyStringSelector,
} from "@selectors/countries.selector";
import { addNotification } from "@actions/notifications.actions";
import DownloadBtn from "@components/DownloadBtn/DownloadBtn";
import Modal from "@components/Modal/Modal";
import LineEllipsis from "@components/LinesEllipsis/LinesEllipsis";
import NoMatches from "@components/NoMatches/NoMatches";
import Cell from "@components/NewAwesomeTable/Cell";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { ROW_HEIGHT, TAKE, useMenuExamplesData } from "./useMenuExamplesData";
import { IconClose, IconSortASC, IconSortDESC } from "@icons";
import "./MenuExamplesPopup.styles.scss";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";

const IconSort = ({ isShown, sortDirection, position = "left" }) => {
    if (!isShown) return null;
    const classNames = cn(
        "MenuExamplesPopup__icon",
        `MenuExamplesPopup__icon--${position}`
    );
    return sortDirection === "asc" ? (
        <Icon type="small" className={classNames}>
            <IconSortASC />
        </Icon>
    ) : (
        <Icon type="small" className={classNames}>
            <IconSortDESC />
        </Icon>
    );
};

const MenuExamplesPopup = ({
    fetch,
    isShown,
    close,
    currency,
    currentCountry,
    addNotification,
    fetchExport,
    downloadUrl,
}) => {
    const {
        download,
        tableData,
        sort,
        getMenuExamples,
        totalCountExamples,
        isLoading,
        handleSortColumn,
        searchQuery,
        handleChangeSearchQuery,
    } = useMenuExamplesData({ fetch, addNotification, isShown, fetchExport, downloadUrl });
    const { getRem } = useContext(GlobalContext);
    const isSensorShown = useMemo(
        () => tableData.length !== totalCountExamples,
        [tableData.length, totalCountExamples],
    );
    const itemCount = useMemo(
        () => Math.min(tableData.length + (isLoading ? TAKE : 0), totalCountExamples),
        [tableData.length, isLoading, totalCountExamples],
    );

    const handleVisibilityChange = isVisible => {
        if (!isLoading && isVisible) {
            getMenuExamples();
        }
    };

    return (
        <Modal shown={isShown} hasOverlay closeModal={close} closeOnOutsideClick={false}>
            <div className="MenuExamplesPopup">
                <div className="MenuExamplesPopup__actions">
                    <div className="MenuExamplesPopup__search">
                        <input
                            className="MenuExamplesPopup__input"
                            type="text"
                            value={searchQuery}
                            onChange={e => handleChangeSearchQuery(e.target.value)}
                            placeholder="Find a term"
                            autoFocus
                        />

                        {searchQuery && (
                            <Icon type="small" className="MenuExamplesPopup__close-icon">
                                <IconClose
                                    onClick={() => handleChangeSearchQuery("")}
                                />
                            </Icon>
                        )}
                    </div>

                    <div className="MenuExamplesPopup__download">
                        <DownloadBtn onClick={download} />
                    </div>
                </div>

                <div className="MenuExamplesPopup__content">
                    <div className="MenuExamplesPopup__header">
                        <div className="MenuExamplesPopup__row">
                            <Cell
                                className="MenuExamplesPopup__column"
                                options="head white hv right"
                                onClick={() => handleSortColumn(2)}
                            >
                                <div className="MenuExamplesPopup__cell-wrapper">
                                    <IconSort
                                        sortDirection={sort.direction}
                                        isShown={sort.column === 2}
                                    />
                                    Item
                                </div>
                            </Cell>
                            <Cell className="MenuExamplesPopup__column" options="head white">
                                Description
                            </Cell>
                            <Cell
                                className="MenuExamplesPopup__column"
                                options="head white hv"
                                onClick={() => handleSortColumn(4)}
                            >
                                <IconSort
                                    sortDirection={sort.direction}
                                    isShown={sort.column === 4}
                                />
                                Price {currentCountry === "USA" ? "" : `(${currency.value})`}
                            </Cell>
                            <Cell
                                className="MenuExamplesPopup__column"
                                options="head white hv center"
                                onClick={() => handleSortColumn(1)}
                            >
                                <div className="MenuExamplesPopup__cell-inner">
                                    <IconSort
                                        sortDirection={sort.direction}
                                        isShown={sort.column === 1}
                                    />
                                    Venue
                                </div>
                            </Cell>
                        </div>
                    </div>

                    {!isLoading && tableData.length === 0 && <NoMatches />}

                    <div className="MenuExamplesPopup__table">
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                        height={height}
                                        width={width}
                                        itemSize={getRem(ROW_HEIGHT)}
                                        itemCount={itemCount}
                                        outerElementType={CustomScrollbarsVirtualList}
                                        innerElementType={forwardRef(
                                            ({ children, ...props }, ref) => {
                                                return (
                                                    <div
                                                        ref={ref}
                                                        {...props}
                                                        className="MenuExamplesPopup__rows"
                                                    >
                                                        {children}
                                                        {isSensorShown && (
                                                            <VisibilitySensor
                                                                onChange={handleVisibilityChange}
                                                                partialVisibility={tableData.length > 0}
                                                            >
                                                                <div className="MenuExamplesPopup__loader" />
                                                            </VisibilitySensor>
                                                        )}
                                                    </div>
                                                );
                                            },
                                        )}
                                    >
                                        {({ index, style }) => {
                                            const item = tableData[index];

                                            return (
                                                <div
                                                    className="MenuExamplesPopup__row"
                                                    style={style}
                                                >
                                                    <Cell
                                                        className="MenuExamplesPopup__column"
                                                        options={`bd-right white bold first high right ${item ? "" : "load"}`}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item ? item.menuItemName : "",
                                                            }}
                                                        />
                                                    </Cell>
                                                    <Cell
                                                        className="MenuExamplesPopup__column"
                                                        options={`lighter no-pr high ${item ? "" : "load"}`}
                                                    >
                                                        <div className="MenuExamplesPopup__text">
                                                            {item && item.description && (
                                                                <LineEllipsis lines={3}>
                                                                    {item.description}
                                                                </LineEllipsis>
                                                            )}
                                                        </div>
                                                    </Cell>
                                                    <Cell
                                                        className="MenuExamplesPopup__column"
                                                        options={`high ${item ? "" : "load"}`}
                                                    >
                                                        <span className="MenuExamplesPopup__currency">
                                                            {(item && item.price !== "-") ? currency.symbol : null}
                                                        </span>
                                                        {item && item.price}
                                                    </Cell>
                                                    <Cell
                                                        className="MenuExamplesPopup__column"
                                                        options="darken last high center"
                                                    >
                                                        {item && item.restaurantName}
                                                    </Cell>
                                                </div>
                                            )
                                        }}
                                    </List>
                                );
                            }}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default connect(
    state => ({
        currentCountry: state.countries.current,
        currency: {
            value: currentCurrencyStringSelector(state),
            symbol: currentCurrencySelector(state),
        },
    }),
    dispatch => ({
        addNotification: notification => dispatch(addNotification(notification)),
    }),
)(memo(MenuExamplesPopup));
