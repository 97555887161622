import { PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import { components } from "@apiSchema";

type Slice = {
    id: number;
    name: string;
    isTableSlice?: boolean;
    columns?: SlicesEnum[];
    columnsOrder?: (number | string)[];
    visibleForCountries?: string[];
};

export enum SlicesEnum {
    Segments = 1000,
    HostedLocations = 1018,
    RestaurantTypes = 1001,
    Regions = 1002,
    Cuisines = 1003,
    DayParts = 1004,
    MenuParts = 1005,
    ItemTypes = 1006,
    Ethnic = 1007,
    NonEthnic = 1008,
    Total = 1009,
    Period = 1010,
    PredictPeriod = 1011,
    OneYearTrend = 1012,
    FourYearTrend = 1013,
    FoodTrucks = 1015,
    Restaurants = 1016,
    PeriodQuarter = 1019,
    PredictPeriodQuarter = 1020,
    PredictedFourYearTrend = 1021,
    PastGrowth = 9997,
    PredictedGrowth = 9998,
    Consumers = 9999,
}

export const ORIGINAL_GROWTH_SLICES = [
    SlicesEnum.OneYearTrend,
    SlicesEnum.FourYearTrend,
    SlicesEnum.PredictedFourYearTrend,
];

export const CUSTOM_GROWTH_SLICES = [
    SlicesEnum.PastGrowth,
    SlicesEnum.PredictedGrowth,
];

const GROWTH_SLICES = [
    ...ORIGINAL_GROWTH_SLICES,
    ...CUSTOM_GROWTH_SLICES,
];

enum ItemTypesEnum {
    AppetizerSoupChili = 1,
    AppetizerSalad = 2,
    DeliSaladAppetizer = 3,
    AppetizerFries = 4,
    PotatoSkins = 5,
    FriedProteinApp = 6,
    FriedVeggieApp = 7,
    FriedOtherApp = 8,
    NonFriedProteinApp = 9,
    NonFriedVeggieApp = 10,
    Wings = 11,
    AppetizerNachos = 12,
    AppetizerQuesadilla = 13,
    AppetizerDip = 14,
    BreadAppetizer = 15,
    Sampler = 16,
    OtherApp = 17,
    HotSandwich = 18,
    ColdSandwich = 19,
    Burger = 20,
    HotDogSausage = 21,
    SaladEntree = 22,
    Pasta = 23,
    BeefMainEntree = 24,
    ChickenMainEntree = 25,
    FishMainEntree = 26,
    ShellfishMainEntree = 27,
    PorkMainEntree = 28,
    OtherProteinMainEntree = 29,
    VeggieMainEntree = 30,
    HandheldProtein = 31,
    RiceEntree = 32,
    Pizza = 33,
    MexicanEntree = 34,
    BreakfastStarch = 35,
    EggDish = 36,
    EntreeStewSoupChili = 37,
    CombosMultiProtein = 38,
    OtherEntree = 39,
    SideSoupChili = 40,
    SaladSide = 41,
    RiceSide = 42,
    FriesSide = 43,
    MashedPotato = 44,
    BakedPotato = 45,
    OtherPotatoSide = 46,
    FriedVeggieSide = 47,
    NonFriedVeggieSide = 48,
    ProteinSide = 49,
    FruitSide = 50,
    Beans = 51,
    CondimentTopping = 52,
    BreadSide = 53,
    DeliSaladSide = 54,
    OtherSide = 55,
    DessertBar = 56,
    BreadPudding = 57,
    BreakfastBakery = 58,
    Brownie = 59,
    Cake = 60,
    CheesePlatter = 61,
    Cheesecake = 62,
    CobblerCrisp = 63,
    Cookie = 64,
    CremeBrulee = 65,
    CustardFlan = 66,
    DessertBeverage = 67,
    Ethnic_Dessert = 68,
    FrozenTreat = 69,
    FruitDessert = 70,
    GenericDessert = 71,
    MixedDessert = 72,
    OtherDessert = 73,
    Pastry = 74,
    Pie = 75,
    PuddingJello = 76,
    Sundae = 77,
    Tiramisu = 78,
    BottledAlcohol = 79,
    Beer = 80,
    BottledWater = 81,
    CSD = 82,
    SparklingWine = 83,
    Coffee = 84,
    EnergyDrink = 85,
    HotChocolate = 86,
    HotTea = 87,
    BlendedDrink = 88,
    IcedCoffee = 89,
    IcedTea = 90,
    Juice = 91,
    Lemonade = 92,
    Margarita = 93,
    Martini = 94,
    Milk = 95,
    CocktailMixedDrink = 96,
    OtherNonFizzyDrink = 97,
    Other = 98,
    PremiumCoffee = 99,
    PremiumIcedCoffee = 100,
    SportsDrink = 101,
    Wine = 102,
    HardSeltzer = 103,
    HardCider = 104,
    OtherAdultBev = 105,
    OtherNaBev = 106,
}

export const SLICES: Slice[] = [
    {
        id: SlicesEnum.Segments,
        name: 'Segments',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.Segments,
            ...GROWTH_SLICES,
            SlicesEnum.HostedLocations,
            SlicesEnum.Restaurants,
        ],
        columnsOrder: [
            1, /* QSR */
            28, /* Fast Casual */
            2, /* Midscale */
            3, /* Casual Dining */
            4, /* Fine Dining */
            7, /* Schools */
            6, /* C&U */
            5, /* Hospital */
            9, /* Long Term Care */
            56, /* Restaurants */
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.HostedLocations,
        name: 'Segments', /* Hosted locations */
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.HostedLocations,
            SlicesEnum.Restaurants,
        ],
    },
    {
        id: SlicesEnum.RestaurantTypes,
        name: 'Restaurant types',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.RestaurantTypes,
            ...GROWTH_SLICES,
            SlicesEnum.FoodTrucks, /* US Innovators */
        ],
        columnsOrder: [
            3,
            2,
            1,
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.Regions,
        name: 'Regions',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.Regions,
            ...GROWTH_SLICES,
        ],
        columnsOrder: [
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.Cuisines,
        name: 'Cuisines',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.Cuisines,
            ...GROWTH_SLICES,
        ],
        columnsOrder: [
            1008, /* NonEthnic */
            1007, /* Ethnic */
            2, /* American */
            4, /* BBQ */
            5, /* Burger */
            9, /* Coffee & Bakery */
            13, /* Dessert & Snack */
            19, /* Pizza */
            20, /* Sandwich */
            21, /* Seafood */
            23, /* Southern */
            24, /* Steakhouse */
            6, /* Caribbean */
            22, /* Central & So America */
            18, /* Mexican */
            7, /* Chinese */
            14, /* Indian */
            16, /* Japanese */
            17, /* Korean */
            25, /* Thai */
            3, /* Other Asian */
            11, /* French */
            12, /* Mediterranean */
            15, /* Italian */
            10, /* Other European */
            1, /* African */
            8, /* Mixed Ethnicity */
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.DayParts,
        name: 'Day parts',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.DayParts,
            ...GROWTH_SLICES,
        ],
        columnsOrder: [
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.MenuParts,
        name: 'Menu parts',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.MenuParts,
            ...GROWTH_SLICES,
        ],
        columnsOrder: [
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.ItemTypes,
        name: 'Item types',
        isTableSlice: true,
        columns: [
            SlicesEnum.Total,
            SlicesEnum.ItemTypes,
            ...GROWTH_SLICES,
        ],
        columnsOrder: [
            ItemTypesEnum.AppetizerSalad,
            ItemTypesEnum.DeliSaladAppetizer,
            ItemTypesEnum.AppetizerSoupChili,
            ItemTypesEnum.FriedProteinApp,
            ItemTypesEnum.NonFriedVeggieApp,
            ItemTypesEnum.FriedOtherApp,
            ItemTypesEnum.NonFriedProteinApp,
            ItemTypesEnum.NonFriedVeggieSide,
            ItemTypesEnum.AppetizerDip,
            ItemTypesEnum.BreadAppetizer,
            ItemTypesEnum.AppetizerFries,
            ItemTypesEnum.PotatoSkins,
            ItemTypesEnum.AppetizerNachos,
            ItemTypesEnum.AppetizerQuesadilla,
            ItemTypesEnum.Wings,
            ItemTypesEnum.Sampler,
            ItemTypesEnum.OtherApp,
            ItemTypesEnum.HotSandwich,
            ItemTypesEnum.ColdSandwich,
            ItemTypesEnum.Burger,
            ItemTypesEnum.HotDogSausage,
            ItemTypesEnum.HandheldProtein,
            ItemTypesEnum.BeefMainEntree,
            ItemTypesEnum.ChickenMainEntree,
            ItemTypesEnum.FishMainEntree,
            ItemTypesEnum.PorkMainEntree,
            ItemTypesEnum.ShellfishMainEntree,
            ItemTypesEnum.VeggieMainEntree,
            ItemTypesEnum.OtherProteinMainEntree,
            ItemTypesEnum.MexicanEntree,
            ItemTypesEnum.Pasta,
            ItemTypesEnum.Pizza,
            ItemTypesEnum.RiceEntree,
            ItemTypesEnum.SaladEntree,
            ItemTypesEnum.EntreeStewSoupChili,
            ItemTypesEnum.BreakfastStarch,
            ItemTypesEnum.EggDish,
            ItemTypesEnum.CombosMultiProtein,
            ItemTypesEnum.OtherEntree,
            ItemTypesEnum.SaladSide,
            ItemTypesEnum.DeliSaladSide,
            ItemTypesEnum.SideSoupChili,
            ItemTypesEnum.FriesSide,
            ItemTypesEnum.BakedPotato,
            ItemTypesEnum.MashedPotato,
            ItemTypesEnum.OtherPotatoSide,
            ItemTypesEnum.Beans,
            ItemTypesEnum.FriedVeggieSide,
            ItemTypesEnum.NonFriedVeggieSide,
            ItemTypesEnum.FruitSide,
            ItemTypesEnum.BreadSide,
            ItemTypesEnum.ProteinSide,
            ItemTypesEnum.RiceSide,
            ItemTypesEnum.CondimentTopping,
            ItemTypesEnum.OtherSide,
            ItemTypesEnum.Cake,
            ItemTypesEnum.Cheesecake,
            ItemTypesEnum.Pie,
            ItemTypesEnum.CobblerCrisp,
            ItemTypesEnum.BreadPudding,
            ItemTypesEnum.CremeBrulee,
            ItemTypesEnum.CustardFlan,
            ItemTypesEnum.Tiramisu,
            ItemTypesEnum.FrozenTreat,
            ItemTypesEnum.Sundae,
            ItemTypesEnum.Brownie,
            ItemTypesEnum.Cookie,
            ItemTypesEnum.DessertBar,
            ItemTypesEnum.BreakfastBakery,
            ItemTypesEnum.Pastry,
            ItemTypesEnum.CheesePlatter,
            ItemTypesEnum.DessertBeverage,
            ItemTypesEnum.Ethnic_Dessert,
            ItemTypesEnum.FruitDessert,
            ItemTypesEnum.PuddingJello,
            ItemTypesEnum.GenericDessert,
            ItemTypesEnum.MixedDessert,
            ItemTypesEnum.OtherDessert,
            ItemTypesEnum.CSD,
            ItemTypesEnum.Juice,
            ItemTypesEnum.Lemonade,
            ItemTypesEnum.Milk,
            ItemTypesEnum.OtherNonFizzyDrink,
            ItemTypesEnum.Coffee,
            ItemTypesEnum.IcedCoffee,
            ItemTypesEnum.PremiumCoffee,
            ItemTypesEnum.PremiumIcedCoffee,
            ItemTypesEnum.HotTea,
            ItemTypesEnum.IcedTea,
            ItemTypesEnum.HotChocolate,
            ItemTypesEnum.BlendedDrink,
            ItemTypesEnum.EnergyDrink,
            ItemTypesEnum.SportsDrink,
            ItemTypesEnum.BottledWater,
            ItemTypesEnum.OtherNaBev,
            ItemTypesEnum.Other,
            ItemTypesEnum.Beer,
            ItemTypesEnum.SparklingWine,
            ItemTypesEnum.Wine,
            ItemTypesEnum.BottledAlcohol,
            ItemTypesEnum.Margarita,
            ItemTypesEnum.Martini,
            ItemTypesEnum.CocktailMixedDrink,
            ItemTypesEnum.HardSeltzer,
            ItemTypesEnum.HardCider,
            ItemTypesEnum.OtherAdultBev,
            'rest', /* items which order doesn't matter */
        ],
    },
    {
        id: SlicesEnum.Ethnic,
        name: 'ethnic',
    },
    {
        id: SlicesEnum.NonEthnic,
        name: 'nonethnic',
    },
    {
        id: SlicesEnum.Total,
        name: 'all',
    },
    {
        id: SlicesEnum.Period,
        name: 'period   ',
    },
    {
        id: SlicesEnum.PredictPeriod,
        name: 'predictPeriod',
    },
    {
        id: SlicesEnum.OneYearTrend,
        name: '1 year trend',
    },
    {
        id: SlicesEnum.FourYearTrend,
        name: '4 year trend',
    },
    {
        id: SlicesEnum.FoodTrucks,
        name: 'Food trucks',
    },
    {
        id: SlicesEnum.Restaurants,
        name: 'Restaurants',
    },
    {
        id: SlicesEnum.PeriodQuarter,
        name: 'periodQuarter',
    },
    {
        id: SlicesEnum.PredictPeriodQuarter,
        name: 'predictPeriodQuarter',
    },
    {
        id: SlicesEnum.PredictedFourYearTrend,
        name: 'predict4YearTrend',
    },
    {
        id: SlicesEnum.Consumers,
        name: 'Consumers',
        isTableSlice: true,
        visibleForCountries: ['usa'],
        columns: [
            SlicesEnum.Total,
            SlicesEnum.Consumers, /* Flavor data */
        ],
    },
];


interface iByoDataSlice {
    id?: number;
    name?: string | null;
    value?: string | number | null;
    valueNumeric?: number | null;
    baseSize?: string | null;
}

type ByoKeywordData = {
    keyword: components['schemas']['Application.Byo.Models.ByoViewModel']['keyword'];
    pairedFlavors: string[];
    flavorData: {
        hasData: boolean;
        permissionLevel: PermissionLevelEnum;
    };
    slices: Record<string, iByoDataSlice[]>;
};

export function getKeywordData(rawData: components['schemas']['Application.Byo.Models.ByoViewModel']) {
    const slices: ByoKeywordData['slices'] = {};
    SLICES.forEach((slice) => {
        [1, 2, 3].forEach(i => { /* analytics types*/
            [1, 2].forEach(j => { /* values types */
                /* "slice id":"analytics type":"values type" */
                slices[`${i}:${j}:${slice.id}`] = [];
            });
        });
    });

    (rawData.tables || []).forEach((cell) => {
        const {
            analyticsType,
            count,
            id,
            slice: sliceId,
            value,
            realValue,
            name,
            baseSize,
        } = cell;

        const finalValue: iByoDataSlice['value'] = realValue ?? Number(value) ?? 'N/A';
        let finalName: iByoDataSlice['name'] = '';

        switch (sliceId) {
            case 1012: finalName = 'Past Growth: 12 months'; break;
            case 1013: finalName = 'Past Growth: 4 Years'; break;
            case 1021: finalName = 'Predicted Growth: 4 Years'; break;
            default: finalName = name; break;
        }

        slices[`${analyticsType}:1:${sliceId}`].push({
            id,
            name: finalName,
            value,
            valueNumeric: finalValue,
            baseSize,
        });
        slices[`${analyticsType}:2:${sliceId}`] = [
            ...slices[`${analyticsType}:2:${sliceId}`],
            {
                id,
                name: finalName,
                value: (sliceId === 1012 || sliceId === 1013) ? finalValue : count,
                baseSize,
            }
        ];
    });

    const {
        flavorData: flavor = {},
    } = rawData;

    const {
        accessLevel: flavorAccessLevel,
        hasData: hasFlavorData,
        data: flavorData,
    } = flavor;

    [1, 2, 3].forEach(i => { /* analytics types*/
        [1, 2].forEach(j => { /* values types */
            slices[`${i}:${j}:${9999}`] = [
                {
                    id: 1,
                    name: '% know it',
                    value: flavorData?.knowIt || '-',
                    valueNumeric: flavorData?.knowIt,
                    baseSize: '-',
                },
                {
                    id: 2,
                    name: '% have tried it',
                    value: flavorData?.haveTriedIt || '-',
                    valueNumeric: flavorData?.haveTriedIt,
                    baseSize: '-',
                },
                {
                    id: 3,
                    name: '% love or like it',
                    value: flavorData?.loveOrLikeIt || '-',
                    valueNumeric: flavorData?.loveOrLikeIt,
                    baseSize: '-',
                },
            ];
        });
    });

    return {
        keyword: rawData.keyword,
        pairedFlavors: rawData.pairedFlavors,
        flavorData: {
            hasData: hasFlavorData,
            permissionLevel: PermissionSection.getPermissionLevel(flavorAccessLevel),
        },
        slices,
    };
}