import React from "react";
import MultipleInput from "@components/MultipleInput/MultipleInput";

const AdditionalFilters = (
    {
        addKeywords,
        removeKeyword,
        keywords,
        words,
        fullLayout = false,
        showCounter = false
    }
) => {
    const inputModifiers = ['filters', ...fullLayout ? ['light'] : []];

    return (
        <div className={`additional-filters ${fullLayout ? 'additional-filters--is-full' : ''}`}>
            <div className="additional-filters__title">
                FILTER YOUR ITEMS
            </div>
            <div className="additional-filters__subtitle">
                Use ingredients, flavors, and other keywords to filter your results.
            </div>
            <div className="additional-filters__wrap">
                <div className="additional-filter">
                    <div className="additional-filter__accent">
                        only items that <span className="additional-filter__highlight">include all</span> of these terms
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && <div className="additional-filter__input-counter">{words.filter(word => word.wordType === 4).length} keywords</div>}
                        <MultipleInput
                            submittedItems={ words.filter(word => word.wordType === 4)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 4 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({id}) => !words.map(({id}) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                        />
                    </div>
                </div>
                <div className="additional-filter">
                    <div className="additional-filter__accent">
                        only items that <span className="additional-filter__highlight">include any</span> of these terms
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && <div className="additional-filter__input-counter">{words.filter(word => word.wordType === 3).length} keywords</div>}
                        <MultipleInput
                            submittedItems={words.filter(word => word.wordType === 3)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 3 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({id}) => !words.map(({id}) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                        />
                    </div>
                </div>
                <div className="additional-filter">
                    <div className="additional-filter__accent">
                        <span className="additional-filter__highlight">EXCLUDE ITEMS</span>
                        &nbsp;that contain any of these keyword
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && <div className="additional-filter__input-counter">{words.filter(word => word.wordType === 2).length} keywords</div>}
                        <MultipleInput
                            submittedItems={words.filter(word => word.wordType === 2)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 2 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({id}) => !words.map(({id}) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdditionalFilters;