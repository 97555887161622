import React, { useReducer, useLayoutEffect, useState, useEffect } from "react";
import PT from "prop-types";
import Scrollbar from "react-scrollbars-custom";
import AutoComplete from "../AutoComplete/AutoComplete";
import { capitalize, compareInLC, getEmptyKeywordModel } from "@core/old_helpers";
import "./MultipleInput.scss";
import MultipleInputWordExtensions from "./MultipleInputWordExtensions";

const InputLabels = ({children, count, isOpen}) => (
    <label className="multiple-input__label">
        {children}
        <span className="multiple-input__counter" style={{transform: `translateX(${isOpen ? '-20px' : '0'})`}}>{count} keywords</span>
    </label>
);
const InputWrapper = ({hasWrapper, children, count, isOpen}) => hasWrapper ? <InputLabels count={count} isOpen={isOpen} >{children}</InputLabels> : children;

const MultipleInput = (
    {
        submittedItems,
        keywords = [],
        words = [],
        onSubmit,
        removeItem,
        modifiers = [],
        readOnly = false,
        onlyFromList = false,
        placeholder = '',
        allowArrowKeys = false,
        showCounter = false,
        isDisabled = false,
        isUpperCase = false,
    }
    ) => {
        const [ state, dispatch ] = useReducer(reducer, initialState);
        const [errorNotification, setErrorNotification] = useState('');
        const {
            query,
            error,
            isExtensionsShown,
            wordExtensionsData,
            extensionX,
            extensionY,
        } = state;
        const DEFAULT_ACTIVE_OPTION_INDEX = -1;

        const [activeOptionIndex, setActiveOptionIndex] = useState(DEFAULT_ACTIVE_OPTION_INDEX);
        const [inputValue, setInputValue] = useState("");

        useEffect(() => {
            let timer;
            if (errorNotification) {
                timer = setTimeout(() => {setErrorNotification('')}, 3000);
            }
            return () => {
                clearTimeout(timer);
            };
        }, [errorNotification]);

        useEffect(() => {
            if (activeOptionIndex >= 0 && autoCompleteData[activeOptionIndex]) {
                setInputValue(autoCompleteData[activeOptionIndex].name);
            }
        }, [activeOptionIndex]);

        useLayoutEffect(() => {
            setInputValue(query);
            setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
        }, [query]);


        useLayoutEffect(() => {
            dispatch({query: "", error: null});
            setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
        }, [ submittedItems ]);

        const handlePaste = (event) => {
            if (!onlyFromList) {
                const value = event.clipboardData.getData('Text');

                if (value.indexOf('\n') >= 0) {
                    const rawItems = value.split('\n')
                        .map(line => line.trim().toLowerCase())
                        .filter(line => !!line);

                    const items = Array.from(new Set(rawItems))
                        .filter(line => isKeywordValid(line));

                    setTimeout(() => {
                        onSubmit(items.map(item => {
                            const existingKeyword = keywords.find(({name}) => compareInLC(name, item));

                            return existingKeyword  || getEmptyKeywordModel(item);
                        }));
                    }, 0);

                    const removedWordsCount = rawItems.length - items.length;

                    if (removedWordsCount > 0) {
                        setErrorNotification(`${removedWordsCount} duplicate items were excluded from list`);
                    }
                }
            }
        };

        const isKeywordValid = (keyword) => {
            const isWordInList = words.some(({name}) => compareInLC(name, keyword));

            const alreadySubmittedKeyword = submittedItems.find(
                i => compareInLC(i.name, keyword)
            );

            return !isWordInList && !alreadySubmittedKeyword;
        };

        const handleKeyPress = ({which}) => {
            const trimmedQuery = allowArrowKeys ? inputValue.trim() : query.trim();
            if (which === 13 && trimmedQuery.trim().length > 0) {
                const isValid = isKeywordValid(trimmedQuery);

                if (isValid) {
                    const existingKeyword = keywords.find(({name}) => compareInLC(name, trimmedQuery));
                    if (onlyFromList) {
                        if (existingKeyword) {
                            onSubmit([existingKeyword])
                        }
                    } else {
                        onSubmit([existingKeyword || getEmptyKeywordModel(trimmedQuery)]);
                    }
                } else {
                    dispatch({
                        error: {
                            category: "alreadyExist",
                        },
                    });
                }
            }
        };

        const handleWordHover = wordExtensions => event => {
            if (wordExtensions && wordExtensions.length > 0 && !isExtensionsShown) {
                const boundingRect = event.currentTarget.getBoundingClientRect();
                dispatch({
                    isExtensionsShown: true,
                    wordExtensionsData: wordExtensions,
                    extensionX: boundingRect.left + boundingRect.width / 2,
                    extensionY: boundingRect.top + boundingRect.height,
                });
            }
        };

        const handleWordMouseOut = () => {
            if (wordExtensionsData.length > 0) {
                dispatch({
                    isExtensionsShown: false,
                });
            }
        };

        let errorCategory;
        if( error && error.category) {
            errorCategory = `"${query}" is already in the  list`
        }

        let autoCompleteData;
        if (query.length < 1) {
            autoCompleteData = [];
        } else {
            autoCompleteData = keywords
                .filter(
                    ({name, id}) =>
                        name.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                        !submittedItems.some(i => i.id === id)
                )
            .sort((a,b) => b.name - b.name).sort((a,b) => {
                 return  b.name.toLowerCase().startsWith(query.toLowerCase()) - a.name.toLowerCase().startsWith(query.toLowerCase());
            });
        }

        let errorMessage = error &&
         (
            <div className="multiple-input__error">
                {errorCategory}
            </div>
        );




        const wrapperClassList = new Set([
            "multiple-input",
            ...modifiers.map(i => `multiple-input--${i}`),
        ]);

        const inputFieldClassList = new Set([
            "multiple-input__field",
            error ? "multiple-input__field--error" : "",
            showCounter ? "multiple-input__field--has-counter" : "",
            isDisabled ? "multiple-input__field--disabled" : '',
        ]);

        const clearBtnClassList = new Set([
            "multiple-input__clear",
            query.length > 0 ? "multiple-input__clear--visible" : "",
        ]);

        const className = new Set([
            "multiple-input__words",
            readOnly
                ? "multiple-input__words--readOnly"
                : ""
        ]);

        const handleArrows = (event) => {
            const trimmedQuery = query.trim();
            if ( !allowArrowKeys || !trimmedQuery.length || error) return;

            if (event.key === "ArrowDown") {
                setActiveOptionIndex( activeOptionIndex === autoCompleteData.length - 1 ? 0 : activeOptionIndex + 1 );

            } else if (event.key === "ArrowUp") {
                setActiveOptionIndex( activeOptionIndex === 0 ? autoCompleteData.length - 1 : activeOptionIndex - 1 );
            }
        }

        return (
            <div className={Array.from(wrapperClassList).join(" ")}>
                {!error && !!errorNotification && (
                    <div className="multiple-input__error">
                        {errorNotification}
                    </div>
                )}
                {error && (
                    errorMessage
                )}
                {!readOnly && (
                    <InputWrapper hasWrapper={showCounter} count={submittedItems.length} isOpen={query.length > 0}>
                        <input
                            type="text"
                            className={Array.from(inputFieldClassList).join(" ")}
                            value={allowArrowKeys ? inputValue : query }
                            onChange={({target}) => {
                                dispatch({
                                    query: isUpperCase ? target.value.toUpperCase() : target.value,
                                    error: null,
                                });
                            }}
                            onPaste={handlePaste}
                            onKeyPress={handleKeyPress}
                            onKeyDown={handleArrows}
                            placeholder={placeholder}
                            disabled={isDisabled}
                        />
                    </InputWrapper>
                )}
                <div className="multiple-input__scrollbar-outer">
                    <Scrollbar>
                        <div className={Array.from(className).join(" ")}>
                            {submittedItems
                                .map(({id, name, wordExtensions}, index) => {
                                    return (
                                        <div
                                            key={`${id}-${index}`}
                                            className={`multiple-input__word${
                                                wordExtensions && wordExtensions.length > 0
                                                    ? " multiple-input__word--has-info"
                                                    : ""
                                            }`}
                                            onMouseEnter={handleWordHover(wordExtensions)}
                                            onMouseMove={handleWordHover(wordExtensions)}
                                            onMouseLeave={handleWordMouseOut}
                                        >
                                            {isUpperCase ? name.toUpperCase() : capitalize(name)}
                                            <button
                                                onClick={() => {
                                                    removeItem(name);
                                                    handleWordMouseOut();
                                                }}
                                                className="multiple-input__word-remove"
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </Scrollbar>
                </div>
                <MultipleInputWordExtensions
                    shown={isExtensionsShown}
                    onClose={handleWordMouseOut}
                    wordExtensions={wordExtensionsData}
                    x={extensionX}
                    y={extensionY}
                />
                <button
                    className={Array.from(clearBtnClassList).join(" ")}
                    onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        dispatch({query: "", error: null});
                        setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
                    }}
                />
                {!error && (
                    <AutoComplete
                        data={autoCompleteData}
                        activeOptionIndex={activeOptionIndex}
                        onClick={(item) => onSubmit([item])}
                        query={query}
                        modifiers={modifiers}
                        isUpperCase={isUpperCase}
                    />
                )}
            </div>
        );
    }
;

const reducer = (state, action) => ({
    ...state,
    ...action,
});

const initialState = {
    query: "",
    error: "",
    isExtensionsShown: false,
    wordExtensionsData: [],
    extensionsX: 0,
    extensionsY: 0,
};

MultipleInput.propTypes = {
    submittedItems: PT.arrayOf(PT.shape({id: PT.number, name: PT.string})),
    keywords: PT.array,
    onSubmit: PT.func,
    removeItem: PT.func,
    modifiers: PT.arrayOf(PT.string),
};

export default MultipleInput;
