import apiConfig from './apiConfig';
import http from './http';

export async function getCountries() {
    return await http.get(apiConfig.get('countries'));
}

export async function getKeywords() {
    return await http.get(apiConfig.get('keywords'));
}

export function getFilters(countryCode) {
    return http.get(apiConfig.getWithId('filters', countryCode));
}

export function getByoData(data) {
    return http.post(apiConfig.get('byo'), data);
}

export async function getByoSettings() {
    return await http.get(apiConfig.get('byo settings'));
}

export async function getByoSetting(id) {
    return await http.get(apiConfig.getWithId('byo settings', id));
}

export async function putByoSettings(data) {
    return await http.put(apiConfig.get('byo settings'), data);
}

export function postByoSettings(data) {
    return http.post(apiConfig.get('byo settings'), data);
}

export async function deleteByoSettings(id) {
    return await http.delete(apiConfig.getWithId('byo settings', id));
}

export function getTopTrendsData(data) {
    return http.post(apiConfig.get('top trends'), data);
}
export function getTopTrendsCopyTable(data) {
    return http.post(apiConfig.get('copy table'), data);
}

export function getFoodProfiles(countrycode) {
    return http.get(apiConfig.getWithId('food profiles', countrycode));
}

export function getFoodProfile(countryCode, id) {
    return http.get(apiConfig.getWithIds('food profile', [countryCode, id]));
}

export function getFoodProfileLTO(countryCode, id) {
    return http.get(apiConfig.getWithIds('food profile lto', [countryCode, id]));
}

export function getGlobalSearchData(data) {
    return http.get(apiConfig.get('global search', data));
}

export function getTopTrendsMenuExamples(data, tableParameters) {
    return http.post(apiConfig.get('top trends menu examples'), {
        ...data,
        tableParameters,
    });
}

export function getCountrySubscribe(countryCode) {
    return http.post(apiConfig.get('country subscribe' ), countryCode)
}

export function exportTopTrendsMenuExamples(data, tableParameters) {
    return http.post(apiConfig.get('export top trends menu examples'), {
        ...data,
        customerFilter: {
            mainFilter: {
                ...data.customerFilter,
            },
            tableParameters,
        }
    });
}

export function getByoMenuExamples(data, tableParameters) {
    return http.post(apiConfig.get('byo menu examples'), {
        ...data,
        tableParameters,
    });
}

export function exportByoMenuExamples(data, tableParameters) {
    return http.post(apiConfig.get('export byo menu examples'), {
        ...data,
        customerFilter: {
            ...data.customerFilter,
            tableParameters,
        }
    });
}

export function exportTopTrends(data) {
    return http.post(apiConfig.get('export top trends'), data);
}

export function exportMenuMatrix(data) {
    return http.post(apiConfig.get('export menu matrix'), data);
}

export function exportInstantChartReport(data) {
    return http.post(apiConfig.get('export BYO instant chart'), data);
}

export function exportSimpleMatrix(data) {
    return http.post(apiConfig.get('export simple matrix'), data);
}

export function exportTrendDetails(data) {
    return http.post(apiConfig.get('export trend details'), data);
}

export function exportLongTermReport(data) {
    return http.post(apiConfig.get('export long term report'), data);
}

export function exportReport(data) {
    return http.get(apiConfig.get('export report', data));
}

export function exportFoodProfile(id) {
    return http.get(apiConfig.get('export food profile', { id }));
}

export function getRestaurantsList(countryCode, restaurantListId) {
    return http.get(apiConfig.getWithIds('restaurant list', [countryCode, restaurantListId]));
}

export function getInstantChart(countryCode, instantChartId) {
    return http.get(apiConfig.getWithIds('instant chart', [countryCode, instantChartId]));
}

export function getRtuReports(id) {
    return http.get(apiConfig.getWithId('rtu reports', id));
}

export function getInstantCharts(countryCode) {
    return http.get(apiConfig.getWithId('instant charts', countryCode));
}

export function getAdminAccess() {
    return http.get(apiConfig.get('admin access'))
}

export function getAllAdminKeywords() {
    return http.get(apiConfig.get('admin all keywords'))
}

export function getAdminKeyword(id) {
    return http.get(apiConfig.get('admin keyword', { id }))
}

export function addAdminKeyword(data) {
    return http.post(apiConfig.get('admin keyword'), data)
}

export function setAdminKeyword(data) {
    return http.put(apiConfig.get('admin keyword'), data)
}

export function getAdminKeywordSearchFilter(searchText) {
    return http.get(apiConfig.getWithId('admin keyword search', searchText))
}

export function deleteAdminKeyword(id) {
    return http.delete(apiConfig.get('admin keyword', { id }))
}

export function uploadAdminKeywordImages(formData) {
    return http.sendFormData(apiConfig.get('admin keyword images'), formData);
}

export function getAdminKeywordSettingData() {
    return http.get(apiConfig.get('admin keyword setting data'))
}
export function getAdminKeywordNames() {
    return http.get(apiConfig.get('admin keyword names'))
}

export function getAdminInstantCharts() {
    return http.get(apiConfig.get('admin instant charts'));
}

export function addAdminInstantChart(data) {
    return http.post(apiConfig.get('admin instant chart save'), data);
}

export function getAdminInstantChart(countryCode, instantChartId) {
    return http.get(apiConfig.getWithIds('admin instant charts', [countryCode, instantChartId]));
}

export function addAdminInstantChartsCategory(data) {
    return http.post(apiConfig.get('admin instant chart category save'), data);
}
export function deleteAdminInstantChart(id) {
    return http.delete(apiConfig.getWithIds('admin instant charts', [id]));
}

export function addAdminInstantChartsSubCategory(data) {
    return http.post(apiConfig.get('admin instant chart subcategory save'), data);
}

export function getAdminInstantChartsCategories(countryCodes) {
    return http.get(apiConfig.get('admin instant charts categories', { countryCodes }));
}

export function getAdminReports() {
    return http.get(apiConfig.get('admin reports'));
}

export function deleteAdminReport(id) {
    return http.delete(apiConfig.getWithIds('admin reports', [id]));
}

export function getAdminReport(rtuReportId) {
    return http.get(apiConfig.getWithIds('admin reports', [rtuReportId]));
}

export function getAdminChildReports(countryCode) {
    return http.get(apiConfig.getWithIds('admin reports child reports', [countryCode]));
}

export function getAdminReportCompanies() {
    return http.get(apiConfig.get('admin reports companies'));
}

export function addAdminReport(data) {
    return http.post(apiConfig.get('admin report add'), data);
}
export function editAdminReport(data) {
    return http.put(apiConfig.get('admin report edit'), data);
}

export function getCustomRestaurantLists(countryCode) {
    return http.get( apiConfig.getWithIds('custom restaurant lists', [countryCode]));
}

export function getCustomRestaurantListsAvailableRestaurants(countryCode) {
    return http.get(apiConfig.getWithIds('custom restaurant lists', ["availableRestaurants", countryCode]));
}

export function getCustomRestaurantList(countryCode, id) {
    return http.get(apiConfig.getWithIds('custom restaurant lists', [countryCode, id]));
}

export function addCustomRestaurantList(data) {
    return http.post(apiConfig.get('custom restaurant lists'), data);
}

export function updateCustomRestaurantList(data) {
    return http.put(apiConfig.get('custom restaurant lists'), data);
}

export function deleteCustomRestaurantList(countryCode, id) {
    return http.delete(apiConfig.getWithIds('custom restaurant lists', [countryCode, id]));
}

export function requestAccess(countryCode, toolLocation) {
    return http.post(apiConfig.get('request access flavor'), { countryCode, toolLocation });
}

export function fetchAdminCategoryReports() {
    return http.get(apiConfig.get('admin category reports'));
}

export function fetchAdminCategoryReport(id) {
    return http.get(apiConfig.getWithId('admin category reports', id));
}

export function createAdminCategoryReport(data) {
    return http.post(apiConfig.get('admin category reports'), data);
}

export function updateAdminCategoryReport(data) {
    return http.put(apiConfig.get('admin category reports'), data);
}

export function deleteAdminCategoryReport(id) {
    return http.delete(apiConfig.getWithIds('admin category reports', [id]));
}

export function fetchAdminDictionaries() {
    return http.get(apiConfig.get('admin dictionaries'));
}

export function getCategoryReports(countryCode) {
    return http.get(apiConfig.getWithId('category reports', countryCode));
}

export function exportByoChart(data) {
    return http.post(apiConfig.get('export BYO chart'), data);
}

export function fetchGetCategoriesFastestGrowing (countryCode) {
    return http.get(apiConfig.getWithId('categories fastest growing', countryCode));
}