import React, { FC, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import "./GlobalSearchResult.scss";
import { iSearchResultsItem, ItemTypeEnum, SearchResults } from "@models/SearchResults";
import { ID, ReportFileExtension } from "@models";
import { Routes } from "@routes";
import Icon from "@components/Icon/Icon";
import { IconChart, IconPDFStroke, IconPpt } from "@icons";
import { ExportTypeEnum } from "@models/ExportTypeEnum";
import cn from "classnames";

interface iItem {
    id: ID;
    name: string;
    subTitle?: string;
    options?: ReactNode[];
    link: string;
}

function getItemOptions(
    {
        item,
        itemTypeId,
        downloadSummaryReport,
        downloadCategoryReport,
    }: {
        item: iSearchResultsItem;
        itemTypeId: ItemTypeEnum;
        downloadSummaryReport: (itemId: ID, exportType: ExportTypeEnum) => void;
        downloadCategoryReport: (itemId: ID, exportType: ReportFileExtension) => void;
    }
): ReactNode[] {
    let options: ReactNode[] = [];

    switch (itemTypeId) {
        case ItemTypeEnum.FoodProfile: {
            if (item.isFoodProfile) {
                options = [(
                    <Link
                        key={`${itemTypeId}-${item.id}`}
                        title="open Food Profile"
                        to={`${Routes.foodProfile}?id=${item.id}&name=${encodeURIComponent(item.name)}`}
                        onClick={(event) => event.stopPropagation()}
                        className="GlobalSearchResult__option"
                    >
                        <Icon className="GlobalSearchResult__icon">
                            <IconChart/>
                        </Icon>
                    </Link>
                )];
            }
            break;
        }

        case ItemTypeEnum.Report: {
            options = [
                (
                    <button
                        key={`${itemTypeId}-${item.id}-${ExportTypeEnum.Pdf}`}
                        title="download PDF"
                        onClick={(event) => {
                            event.preventDefault();
                            downloadSummaryReport(item.id, ExportTypeEnum.Pdf);
                        }}
                        className="GlobalSearchResult__option"
                    >
                        <Icon type='small' className="GlobalSearchResult__icon">
                            <IconPDFStroke/>
                        </Icon>
                    </button>
                ),
                (
                    <button
                        key={`${itemTypeId}-${item.id}-${ExportTypeEnum.Ppt}`}
                        title="download PPT"
                        onClick={(event) => {
                            event.preventDefault();
                            downloadSummaryReport(item.id, ExportTypeEnum.Ppt);
                        }}
                        className="GlobalSearchResult__option"
                    >
                        <Icon type='small' className="GlobalSearchResult__icon">
                            <IconPpt/>
                        </Icon>
                    </button>
                ),
            ];
            break;
        }
        case ItemTypeEnum.CategoryReport: {
            options = [
                (
                    <button
                        key={`${itemTypeId}-${item.id}-${ReportFileExtension.Pdf}`}
                        title="download PDF"
                        onClick={(event) => {
                            event.preventDefault();
                            downloadCategoryReport(item.id, ReportFileExtension.Pdf);
                        }}
                        className="GlobalSearchResult__option"
                    >
                        <Icon type='small' className="GlobalSearchResult__icon">
                            <IconPDFStroke/>
                        </Icon>
                    </button>
                ),
                (
                    <button
                        key={`${itemTypeId}-${item.id}-${ReportFileExtension.Ppt}`}
                        title="download PPT"
                        onClick={(event) => {
                            event.preventDefault();
                            downloadCategoryReport(item.id, ReportFileExtension.Ppt);
                        }}
                        className="GlobalSearchResult__option"
                    >
                        <Icon type='small' className="GlobalSearchResult__icon">
                            <IconPpt/>
                        </Icon>
                    </button>
                ),
            ];
            break;
        }
        default: {
            break;
        }
    }

    return options;
}

const GlobalSearchResult: FC<{
    items: iSearchResultsItem[];
    itemTypeId: ItemTypeEnum;
    title: string;
    isOpened?: boolean;
    handleShowMore?: () => void;
    handleHide?: () => void;
    downloadSummaryReport: (itemId: ID, exportType: ExportTypeEnum) => void;
    downloadCategoryReport: (itemId: ID, exportType: ReportFileExtension) => void;
}> = (
    {
        items = [],
        title,
        itemTypeId,
        isOpened = false,
        handleShowMore,
        handleHide,
        downloadSummaryReport,
        downloadCategoryReport,
    }
) => {
    const slicedItems: iSearchResultsItem[] = useMemo(() => {
        if (isOpened) return items;
        return items.slice(0, itemTypeId === ItemTypeEnum.Report || itemTypeId === ItemTypeEnum.CategoryReport ? 2 : 3)
    }, [isOpened, items]);

    const preparedItems: iItem[] = useMemo(() => {
        return slicedItems.map((item, itemIndex) => ({
            id: item.id,
            name: item.name,
            subTitle: SearchResults.getItemSubTitle(item, itemIndex, itemTypeId),
            link: SearchResults.getItemLink(item, itemTypeId),
            options: getItemOptions({
                item,
                itemTypeId,
                downloadSummaryReport,
                downloadCategoryReport,
            }),
        }));
    }, [slicedItems, itemTypeId]);

    const btn = (
        <span
            className="GlobalSearchResult__more"
            onClick={isOpened ? handleHide : handleShowMore}
        >
            {isOpened ? "HIDE" : "MORE"}
        </span>
    );

    if (items.length === 0) return null;

    return (
        <div className="GlobalSearchResult">
            <div className="GlobalSearchResult__category">
                {title}
            </div>

            {preparedItems.map((item, idx) => {
                const { options = [] } = item;

                const className = cn(
                    'GlobalSearchResult__item',
                    options.length > 0 && `GlobalSearchResult__item--pr${options.length}`
                );

                const content = (
                    <>
                        {item.name}
                        {item.subTitle && <div className="GlobalSearchResult__sub-title">{item.subTitle}</div>}
                        <div className="GlobalSearchResult__options">
                            {options}
                        </div>
                    </>
                );
                if (item.link) {
                    return (
                        <Link
                            to={item.link}
                            className={className}
                            key={idx}
                        >
                            {content}
                        </Link>
                    )
                }

                return (
                    <div
                        key={idx}
                        className={className}
                    >
                        {content}
                    </div>
                )

            })}
            {items.length > 3 && btn}
        </div>)
};

export default GlobalSearchResult;