import React from 'react';
import './NoMatches.styles.scss';

function NoMatches () {
    return (
        <div className="NoMatches">
            No matching results found
        </div>
    );
}

export default NoMatches;