import React, { useMemo } from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import PageHeader from "@components/PageHeader/PageHeader";
import { setCurrent } from "@actions/countries.actions";
import "./HeaderContainer.scss";
import { AVAILABLE_COUNTRIES } from "@reducers/countries.reducer";
import { usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import { matchPath, useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "@routes";

const HeaderContainer = ({ currentCountry, countries, setCurrent }) => {
    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');
    const location = useLocation();

    const isAdminRoute = useMemo(
        () => ADMIN_ROUTES.some(route => matchPath(route, location.pathname)),
        [location.pathname]
    );

    if (isPlatformEnabled && !isAdminRoute) {
        return null;
    }

    return (
        <PageHeader
            currentCountry={currentCountry}
            countries={countries.filter(i => AVAILABLE_COUNTRIES.includes(i.countryCode))}
            setCurrent={setCurrent}
        />
    );
};

const mapStateToProps = state => ({
    countries: state.countries.data,
    currentCountry: state.countries.current,
});

const mapDispatchToProps = dispatch => ({
    setCurrent: countryCode => dispatch(setCurrent(countryCode))
});

HeaderContainer.propTypes = {
    countries: PT.array,
    currentCountry: PT.string,
    setCurrent: PT.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer);
