import React from "react";
import PT from "prop-types";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import "./Dropdown.scss";

const Dropdown = (
    {
        handleChange,
        options,
        ethnic = false,
        arrowLeft = '',
        withGroups,
        onItemMouseEnter = () => {},
        onItemMouseLeave = null,
    }
) => {

    const classList = new Set(["dropdown", ethnic ? "dropdown--ethnic" : ""]);
    const groups = Array.from(
        new Set(
            options.map(({ groupId, groupName }) => JSON.stringify({ id: groupId, name: groupName }))
        )
    ).map((group) => JSON.parse(group)).filter(({ id }) => !!id);

    return (
        <div className={Array.from(classList).join(" ")}>
            <div className="dropdown__arrow" style={{ left: arrowLeft, }}/>
            <div className="dropdown__item">
                {withGroups ? (
                    <>
                        {groups.map(group => (
                            <CheckboxInput
                                className="dropdown__category"
                                key={group.id}
                                onChange={() => handleChange(group.id, true)}
                                labelText={group.name}
                                isChecked={options.filter(option => option.groupId === group.id).every(option => option.isChecked)}
                                isPartialChecked={options.filter(option => option.groupId === group.id).some(option => option.isChecked)}
                                isWhiteMark
                                isSmall
                                isBold
                            />
                        ))}
                    </>
                ) : (
                    <CheckboxInput
                        onChange={() => handleChange('all')}
                        labelText="All items"
                        isChecked={options.every(option => option.isChecked)}
                        isPartialChecked={options.some(option => option.isChecked) && !options.every(option => option.isChecked)}
                        isSmall
                        isBold
                        isWhiteMark
                    />
                )}
            </div>
            {options.map(option => (
                <div key={option.id} className="dropdown__item">
                    <CheckboxInput
                        onChange={() => handleChange(option.id)}
                        labelText={option.name}
                        isChecked={option.isChecked}
                        isSmall
                        isBold={option.name === 'Other Bev'}
                        onMouseEnter={() => onItemMouseEnter(option.id)}
                        onMouseLeave={onItemMouseLeave}
                        />
                </div>
            ))}
        </div>
    );
};

Dropdown.propTypes = {
    items: PT.array,
    onLeft: PT.bool,
    handleCheck: PT.func,
    colIndex: PT.number,
    handleCheckGroup: PT.func,
};

export default Dropdown;
