export const BUBBLE_OPTIONS = [
    {},
    {
        id: 1,
        colors: ["#FFB199", "#FF0844"],
        isAnimated: true,
        width: 370,
        height: 370,
        text: "MENU ITEMS",
        fontFamily: "Poppins"
    },
    {
        id: 2,
        colors: ["#F6D365", "#FDA085"],
        isAnimated: true,
        width: 370,
        height: 370,
        text: "ATTRIBUTES",
        fontFamily: "Poppins"
    },
    {
        id: 3,
        colors: ["#04BEFE", "#3060b0"],
        isAnimated: true,
        width: 370,
        height: 370,
        text: "VENUES",
        fontFamily: "Poppins"
    },
];
