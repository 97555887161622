import { CSSProperties, FC } from "react";
import cn from "classnames";
import "./CellNew.styles.scss";

const CellNew: FC<{
    className?: string;
    modifiers?: string[];
    style?: CSSProperties;
}> = ({
    className = '',
    modifiers = [],
    children,
    style,
}) => {
    const classNames = cn(
        "CellNew",
        modifiers.map(m => `CellNew--${m}`),
        className
    );

    return (
        <div
            className={classNames}
            style={style}
        >
            {children}
        </div>
    )
}

export default CellNew;